import Store from 'Store/store';
import ClientHome from 'Components/Vue-Routes/MyClients/ClientView/ClientHome';
import ClientEdit from 'Components/Vue-Routes/MyClients/ClientView/ClientEdit';
import ClientDocuments from 'Components/Vue-Routes/MyClients/ClientView/ClientDocuments';
import ClientRelationships from 'Components/Vue-Routes/MyClients/ClientView/ClientRelationships';
import ClientInvite from 'Components/Vue-Routes/MyClients/ClientView/ClientInvite';
import ClientUnlock from 'Components/Vue-Routes/MyClients/ClientView/ClientUnlock';
import MyClients from 'Components/Vue-Routes/MyClients';
import AllClients from 'Components/Vue-Routes/MyClients/AllClients';
import ClientView from 'Components/Vue-Routes/MyClients/ClientView';
import OnboardingCompleted from 'Components/Vue-Routes/MyClients/ClientView/OnboardingCompleted/OnboardingCompleted';
import KycSurvey from 'Components/Vue-Routes/KycSurvey';
import ClientMaintenance from 'Components/Vue-Routes/ClientMaintenance';
import FundingV2 from 'Components/Vue-Routes/FundingV2';
import ClientChanges from 'Components/Vue-Routes/ClientChanges';
import MakeDeposit from 'Components/Vue-Routes/FundingV2/MakeDeposit';
import MakeWithdrawal from 'Components/Vue-Routes/FundingV2/MakeWithdrawal';
import MakeTransfer from 'Components/Vue-Routes/FundingV2/MakeTransfer';
import FundingRequestTypes from 'Components/Vue-Routes/FundingV2/FundingRequestTypes';
import ReviewRequest from 'Components/Vue-Routes/FundingV2/ReviewRequest';
import ReviewAddedTransfers from 'Components/Vue-Routes/FundingV2/ReviewAddedTransfers';
import RiskProfileTab from 'Components/Vue-Routes/MyClients/ClientView/ClientEdit/RiskProfileTab/RiskProfileTab';
import FeeManagementTab from 'Components/Vue-Routes/MyClients/ClientView/ClientEdit/FeeManagementTab';
import LegalEntity from 'Components/Vue-Routes/LegalEntity';
import ShareholderPreferences from 'Components/Vue-Routes/MyClients/ClientView/ClientEdit/ShareholderPreferences';
import SubAdvisorConsentDisagree from 'Components/Vue-Routes/KycSurvey/SubAdvisorConsentView/Disagree.vue';

export const clientRoutes = [
  {
    path: '/clients',
    component: MyClients,
    meta: {
      stylesheet: 'old',
    },
    children: [
      {
        path: '/',
        component: AllClients,
        props: (route) => ({ ...route.query }),
        meta: {
          canAccess: () => Store.getters.canViewMyClientsBasicInformation,
          stylesheet: 'old',
        },
      },
      {
        path: ':clientID',
        component: ClientView,
        name: 'ClientViewPage',
        meta: {
          stylesheet: 'old',
        },
        children: [
          {
            path: '/',
            name: 'ClientHomePage',
            component: ClientHome,
            meta: {
              canAccess: () => Store.getters.canViewMyClientsBasicInformation,
              stylesheet: 'old',
            },
          },
          {
            path: 'edit',
            name: 'ClientEditPage',
            component: ClientEdit,
            meta: {
              canAccess: () => Store.getters.canAccessFullClientProfile,
              stylesheet: 'old',
            },
          },
          {
            path: 'risk-profile',
            name: 'RiskProfileTab',
            component: RiskProfileTab,
            meta: {
              canAccess: () => Store.getters.canAccessFullClientProfile,
              stylesheet: 'old',
            },
          },
          {
            path: 'fee-management',
            name: 'FeeManagementTab',
            component: FeeManagementTab,
            meta: {
              canAccess: () => Store.getters.canAccessFullClientProfile,
              stylesheet: 'old',
            },
          },
          {
            path: 'documents',
            component: ClientDocuments,
            meta: {
              stylesheet: 'old',
            },
          },
          {
            path: 'relationships',
            component: ClientRelationships,
            meta: {
              stylesheet: 'old',
            },
          },
          {
            path: 'invite',
            component: ClientInvite,
            meta: {
              stylesheet: 'beta',
            },
          },
          {
            path: 'unlock',
            component: ClientUnlock,
            meta: {
              stylesheet: 'beta',
            },
          },
          {
            path: 'kyc-survey',
            name: 'KycSurveyPage',
            component: KycSurvey,
            meta: {
              canAccess: () => Store.getters.canEditInPersonOnboarding,
              stylesheet: 'beta',
            },
          },
          {
            path: 'subadvisor-consent-disagree',
            name: 'SubAdvisorConsentDisagree',
            component: SubAdvisorConsentDisagree,
            meta: {
              stylesheet: 'beta',
            },
          },
          {
            path: 'client-maintenance',
            name: 'ClientMaintenancePage',
            component: ClientMaintenance,
            meta: {
              canAccess: () => Store.getters.canViewClientDetails && Store.getters.hasFeatureFlag('client_maintenance'),
              stylesheet: 'beta',
            },
          },
          {
            path: 'client-changes',
            name: 'ClientChangesPage',
            component: ClientChanges,
            meta: {
              canAccess: () => Store.getters.canViewClientDetails && Store.getters.hasFeatureFlag('client_maintenance'),
              stylesheet: 'beta',
            },
          },
          {
            path: 'funding-v2',
            component: FundingV2,
            meta: {
              stylesheet: 'beta',
            },
          },
          {
            path: 'funding-v2/request-types',
            component: FundingRequestTypes,
            meta: {
              stylesheet: 'beta',
            },
          },
          {
            path: 'funding-v2/withdrawal',
            component: MakeWithdrawal,
            meta: {
              stylesheet: 'beta',
            },
          },
          {
            path: 'funding-v2/deposit',
            component: MakeDeposit,
            meta: {
              stylesheet: 'beta',
            },
          },
          {
            path: 'funding-v2/transfer',
            component: MakeTransfer,
            meta: {
              stylesheet: 'beta',
            },
          },
          {
            path: 'funding-v2/deposit/review',
            component: ReviewRequest,
            props: true,
            meta: {
              stylesheet: 'beta',
            },
          },
          {
            path: 'funding-v2/withdrawal/review',
            component: ReviewRequest,
            props: true,
            meta: {
              stylesheet: 'beta',
            },
          },
          {
            path: 'funding-v2/transfer/review',
            component: ReviewRequest,
            props: true,
            meta: {
              stylesheet: 'beta',
            },
          },
          {
            path: 'funding-v2/added-transfers',
            component: ReviewAddedTransfers,
            props: true,
            meta: {
              stylesheet: 'beta',
            },
          },
          {
            path: 'onboarding-completed',
            name: 'onboardingCompleted',
            component: OnboardingCompleted,
            props: true,
            meta: {
              stylesheet: 'beta',
            },
          },
          {
            path: 'legal-entity',
            name: 'LegalEntityDetails',
            component: LegalEntity,
            meta: {
              stylesheet: 'beta',
            },
          },
          {
            path: 'shareholder-preferences',
            name: 'ShareholderPreferences',
            component: ShareholderPreferences,
            meta: {
              stylesheet: 'old',
            },
          },
        ],
      },
    ],
  },
];
