<template>
  <v-flex xs6>
    <div v-if="workflow.type != 'funding'" class="form-label strong-text mb-3">
      {{WORKFLOW.STEP_DISPLAY_NAMES[step.name]}}
    </div>
    <div v-else class="form-label strong-text mb-3">
      {{stepNameVerbose}}
    </div>
    <div v-if="workflow.type != 'funding'">
      {{WORKFLOW.STEP_DESCRIPTIONS[step.name]}}
    </div>
    <div v-else>
      {{stepDescription}}
    </div>
  </v-flex>
</template>

<script>
import { WORKFLOW } from 'Services/constants';
import api from 'Services/apiV2';

export default {
  name: 'WorkflowStepName',
  props: {
    step: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      stepDescription: '',
      stepNameVerbose: '',
      WORKFLOW,
    };
  },
  async mounted() {
    const workflowStep = await this.getWorkflowStepFromPreferencesTable();
    if (workflowStep) {
      this.stepDescription = workflowStep.step_description;
      this.stepNameVerbose = workflowStep.step_name_verbose;
    }
  },
  methods: {
    async refreshWorkflow() {
      this.workflowID = this.$route.params.workflowID;
      this.$store.dispatch('fetchWorkflowV2Steps', this.workflowID);
    },
    async getWorkflowStepFromPreferencesTable() {
      const workflowType = this.$store.state.workflow.selectedWorkflow.type;
      const workflowSteps = await api.get(`/workflow_step_firm_preferences/?workflow_type=${workflowType}&limit=100`);
      const stepName = this.step.name;

      const currentStep = workflowSteps.results.find((ws) => ws.step_name === stepName);
      return currentStep;
    },
  },
  computed: {
    workflow() {
      return this.$store.state.workflow.selectedWorkflow;
    },
  },
};
</script>

<style scoped lang="scss">
.v-btn--floating.v-btn--small  {
  height: 24px !important;
  width: 24px !important;
}

</style>
