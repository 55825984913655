<template>
  <PBadge
    :variant="statusToVariant"
    class="row-badge"
  >
    {{statusToLabel}}
  </PBadge>
</template>

<script>
import {
  AKYC_MAP_STATUS_LABEL,
  AKYC_MAP_STATUS_VARIANT,
} from './constants';

export default {
  name: 'StatusBadge',
  props: {
    status: String,
  },
  computed: {
    statusToLabel() {
      return AKYC_MAP_STATUS_LABEL[this.status] || '';
    },
    statusToVariant() {
      return AKYC_MAP_STATUS_VARIANT[this.status];
    },
  },
};
</script>

<style scoped>
.row-badge {
  font-size: 12px !important;
  padding: 4px 8px !important;
}
</style>
