<!-- eslint-disable max-len -->
<template>
  <div class="w-100 mb-3">
    <!-- MOBILE VIEW -->
    <div class="d-block d-lg-none">
      <div class="text-center" v-if="isLoading">
        <PSpinner />
      </div>
      <dl v-else>
        <PCard class="mb-2 shadow-none" body-class="py-0" v-for="client in clients" v-bind:key="client.client_id">
          <div class="kyc-client-row border-bottom border-grey-500">
            <div class="d-flex align-items-center">
              <div class="d-flex flex-column align-items-start">
                <router-link :to="`clients/${client.client_id}`" class="text-primary text-semibold">{{ client.display_name }}</router-link>
                <span class="text-warning" v-if="isClientDisabled(client.client_id)">Changes in progress</span>
              </div>
              <StatusBadge class="text-uppercase font-weight-normal ml-2" :status="client.status" v-if="client.status" />
            </div>
            <div>
              <PButton
                :isIconOnly="true"
                @click="openConfirmInPersonAkycModal([client])"
                appendIcon="users"
                class="p-0 ml-2"
                v-if="!isClientDisabled(client.client_id)"
                variant="white"
              />
              <PButton
                :isIconOnly="true"
                @click="openConfirmNotifyModal([client])"
                appendIcon="envelope"
                class="p-0 ml-2"
                v-if="!isClientDisabled(client.client_id)"
                variant="white"
              />
              <PButton
                :isIconOnly="true"
                appendIcon="check-double"
                variant="white"
                class="p-0 ml-2"
                v-if="!isClientDisabled(client.client_id)"
                @click="openConfirmMarkAsCompleteModal([client])"
              />
            </div>
          </div>
          <div class="kyc-client-row border-bottom border-grey-500">
            <dt class="text-grey-600 text-semibold">Contact</dt>
            <dd class="font-weight-normal">
              <a :href="`tel:+${client.preferred_number}`">{{ formatPhoneNumber(client.preferred_number) }}</a>
              <br>
              <a :href="`mailto:${client.email}`">{{ client.email }}</a>
            </dd>
          </div>
          <div class="kyc-client-row border-bottom border-grey-500">
            <dt class="text-grey-600 text-semibold">Team</dt>
            <dd class="font-weight-normal">{{ client.team }}</dd>
          </div>
          <div class="kyc-client-row border-bottom border-grey-500">
            <dt class="text-grey-600 text-semibold">Due date</dt>
            <dd class="font-weight-normal">
              <div>{{ client.due_date ? moment(client.due_date).format(dateFormat) : 'N/A' }}</div>
              <div>{{ client.last_reminded_at ? ('Notified ' + getLastNotifiedDate(client.last_reminded_at)) : 'Not Notified' }}</div>
            </dd>
          </div>
        </PCard>
      </dl>
      <PCard class="mb-2" v-if="!clients.length">No matching clients</PCard>
    </div>
    <!-- DESKTOP VIEW -->
    <div class="d-none d-lg-block">
      <PTable
        thead-class="fixed-thead"
        tbody-class="bg-white"
        :fields="fields"
        :items="clients"
        :busy="isLoading"
        :sort-by.sync="query.sortBy"
        :sort-desc.sync="query.sortDescending"
        no-local-sorting
        show-empty
        @sort-changed="sort($event)"
      >
        <template #head(checkbox)="data">
          <input id="toggle-all" aria-label="toggle all" type="checkbox" @change="toggleSelectAll()" :indeterminate.prop="someChecked" :checked="allSelected" />
        </template>
        <template #cell(checkbox)="data">
          <input type="checkbox" v-model="selectedClients" :id="data.item.client_id" :value="data.item" v-if="!isClientDisabled(data.item.client_id)" />
        </template>
        <template #head(status)="data">
          <div class="d-flex align-items-center">
            <div v-if="selectedClients.length">
              <span> {{ selectedClients.length }} Selected</span>
            </div>
            <span v-else class="mb-0" for="toggle-all">All</span>
            <PButton v-if="selectedClients.length" variant="primary" prepend-icon="envelope" size="sm" class="btn-thead ml-2" @click="openConfirmNotifyModal(selectedClients)">Notify</PButton>
          </div>
        </template>
        <template #cell(status)="data">
          <StatusBadge class="text-uppercase font-weight-normal" :status="data.item.status" v-if="data.item.status" />
        </template>
        <template #cell(name)="data">
          <div class="d-flex flex-column align-items start">
            <router-link :to="`clients/${data.item.client_id}`" class="text-dark text-semibold">{{ data.item.display_name }}</router-link>
            <span class="text-warning" v-if="isClientDisabled(data.item.client_id)">Changes in progress</span>
          </div>
        </template>
        <template #cell(contact)="data">
          <a :href="`mailto:${data.item.email}`">{{ data.item.email }}</a>
          <br>
          <a :href="`tel:+${data.item.preferred_number}`">{{ formatPhoneNumber(data.item.preferred_number) }}</a>
        </template>
        <template #cell(team)="data">
          {{ data.item.team }}
        </template>
        <template #head(due_date)="data">
          <span class="mb-0"> Due date
            <font-awesome-icon class="pl-1" size="lg" :icon="['fal', sortIcon]" />
          </span>
        </template>
        <template #cell(due_date)="data">
          <div>{{ data.item.due_date ? moment(data.item.due_date).format(dateFormat) : 'N/A' }}</div>
          <div>{{ data.item.last_reminded_at ? ('Notified ' + getLastNotifiedDate(data.item.last_reminded_at)) : 'Not Notified' }}</div>
        </template>
        <template #cell(actions)="{ item }">
          <div class="d-flex justify-content-end">
            <div>
              <PButton
                :id="`btn-in-person-akyc-${item.client_id}`"
                :isIconOnly="true"
                @click="openConfirmInPersonAkycModal([item])"
                appendIcon="users"
                v-show="!isClientDisabled(item.client_id)"
                variant="white"
              />
              <PTooltip
                :target="`btn-in-person-akyc-${item.client_id}`"
                delay="200"
                triggers="hover"
              >
                <span>In-person Annual KYC</span>
              </PTooltip>
            </div>
            <div>
              <PButton
                :id="`btn-notify-${item.client_id}`"
                :isIconOnly="true"
                @click="openConfirmNotifyModal([item])"
                appendIcon="envelope"
                v-show="!isClientDisabled(item.client_id)"
                variant="white"
              />
              <PTooltip
                :target="`btn-notify-${item.client_id}`"
                delay="200"
                triggers="hover"
              >
                <span>Remind client</span>
              </PTooltip>
            </div>
            <div>
              <PButton
                :id="`btn-mark-as-complete-${item.client_id}`"
                :isIconOnly="true"
                appendIcon="check-double"
                variant="white"
                v-show="!isClientDisabled(item.client_id)"
                @click="openConfirmMarkAsCompleteModal([item])"
              />
              <PTooltip
                :target="`btn-mark-as-complete-${item.client_id}`"
                delay="200"
                triggers="hover"
              >
                <span>Mark as complete</span>
              </PTooltip>
            </div>
          </div>
        </template>
        <template #empty>
          <h3>No matching clients</h3>
        </template>
        <template #table-busy>
          <div class="text-center">
            <PSpinner />
          </div>
        </template>
      </PTable>
    </div>
    <div class="d-flex justify-content-center" v-if="hasClientsToLoad && !isLoading">
      <PButton variant="dark" @click="loadClients(query.offset + query.limit)">See more</PButton>
    </div>
    <ConfirmActionModal
      :visible="modalToShow === 'notify'"
      content="Would you like to remind your client(s) about their KYC due date?"
      labelSubmit="Send email"
      labelCancel="Cancel"
      @close-modal="closeConfirmNotifyModal"
    />
    <ConfirmActionModal
      :visible="modalToShow === 'markAsComplete'"
      content="Select the date the annual KYC was completed."
      labelSubmit="Yes"
      labelCancel="Never mind"
      showCalendar
      @close-modal="closeConfirmMarkAsCompleteModal"
    />
    <ConfirmActionModal
      :visible="modalToShow === 'inPersonAkyc'"
      content="This will start Annual KYC workflow for this client. Are you sure you want to proceed?"
      labelSubmit="Yes"
      labelCancel="Never mind"
      @close-modal="closeConfirmInPersonAkycModal"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { formatPhoneNumber } from 'Services/mixins';
import {
  createAnnualKycWorkflow, fetchAnnualKycList, notifyClients, createNoUpdateAkycWorkflow,
} from 'Services/api/AnnualKycList';
import { fetchInProgressWorkflowsForClient, ALL_AKYC_CM_WORKFLOW_STEPS_EXCEPT_CHANGE_REQUEST } from 'Services/api/Workflows';
import StatusBadge from './StatusBadge';
import ConfirmActionModal from './ConfirmActionModal';

export default {
  name: 'ClientsTable',
  mixins: [
    formatPhoneNumber,
  ],
  components: {
    StatusBadge,
    ConfirmActionModal,
  },
  props: {
    clientId: { type: Number, default: () => null },
    notified: { type: String, default: () => null },
    status: { type: String, default: () => null },
    team: { type: Number, default: () => null },
  },
  data() {
    return {
      moment,
      clients: [],
      fields: [
        {
          key: 'checkbox', label: '', thClass: 'border-0 pl-lg-5 align-middle', tdClass: 'pl-lg-5 align-middle',
        },
        {
          key: 'status', label: '', thClass: 'border-0 align-middle', thAttr: { colspan: 2 }, tdClass: 'align-middle',
        },
        {
          key: 'name', label: '', thClass: 'border-0 d-none', tdClass: 'align-middle',
        },
        {
          key: 'contact', label: 'Contact', thClass: 'border-0 text-grey-600 align-middle', tdClass: 'align-middle',
        },
        {
          key: 'team', label: 'Team', thClass: 'border-0 text-grey-600 align-middle', tdClass: 'align-middle',
        },
        {
          key: 'due_date', label: 'Due date', thClass: 'border-0 text-grey-600 align-middle', tdClass: 'align-middle', sortable: true,
        },
        {
          key: 'actions', label: '', thClass: 'border-0 text-grey-600 pr-lg-5 align-middle', tdClass: 'pr-lg-5 align-middle',
        },
      ],
      dateFormat: 'ddd, MMM D, YYYY',
      hasClientsToLoad: false,
      isLoading: true,
      query: {
        limit: 8,
        offset: 0,
        sortBy: 'due_date',
        sortDirection: 'asc',
        sortDescending: false,
        notified: null,
        teamIds: [],
        status: null,
        clientId: null,
      },
      selectedClients: [], // checkbox selected clients
      notifyClients: [], // Note: can be a single client not necessarily the same as selectedClients
      disabledClients: [],
      someChecked: false,
      allSelected: false,
      clientIdResetFilters: false, // a flag to indicate filters reset that triggers by a defined client id. This is used to avoid making the same fetch call multiple times.
      modalToShow: undefined,
    };
  },
  computed: {
    sortIcon() {
      return this.query.sortDirection === 'asc' ? 'chevron-up' : 'chevron-down';
    },
  },
  methods: {
    loadClients(offset) {
      this.isLoading = true;
      this.query.offset = offset;
      fetchAnnualKycList(this.query)
        .then((clients) => {
          this.hasClientsToLoad = clients.length === this.query.limit;

          if (!offset) {
            this.clients = clients;
          } else {
            this.clients = this.clients.concat(clients);
          }
          this.disableClientsWithOngoingChangeRequest();
        })
        .catch(() => {
          this.$emit('show-toast', 'Sorry, something went wrong. Please try again, or contact us for more details.');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    resetTable() {
      this.query.offset = 0;
      this.selectedClients = [];
    },
    sort(event) {
      this.query.sortDirection = event.sortDesc ? 'desc' : 'asc';
      this.query.sortDescending = event.sortDesc;
      this.resetTable();
      this.loadClients(this.query.offset);
    },
    toggleSelectAll() {
      if (this.selectedClients.length) {
        this.selectedClients = [];
      } else {
        this.selectedClients = this.clients
          .filter((id) => !this.disabledClients.includes(id));
      }
    },
    updateCheckboxes() {
      this.someChecked = this.selectedClients.length && (this.selectedClients.length !== this.clients.length);
      this.allSelected = this.selectedClients.length && (this.selectedClients.length === this.clients.length);
    },
    notify(clients) {
      notifyClients(clients)
        .then((res) => {
          this.$emit('show-toast', 'Notifications are sent.');
        })
        .catch((err) => {
          this.$emit('show-toast', 'Something went wrong. Please try again, or contact us for more details.');
          console.error(err);
        })
        .finally(() => {
          this.isLoading = true;
          setTimeout(() => {
            // add delay to wait for database update notified date
            this.loadClients(this.query.offset);
          }, 500);
        });
    },
    markAsComplete(clients) {
      createNoUpdateAkycWorkflow(clients)
        .then(() => {
          this.$emit('show-toast', 'Marking as complete. This may take a few minutes.');
        })
        .catch((err) => {
          this.$emit('show-toast', 'Something went wrong. Please try again, or contact us for more details.');
          console.error(err);
        })
        .finally(() => {
          this.loadClients(this.query.offset);
        });
    },
    redirectToSurvey(clientId) {
      this.$router.push({
        name: 'KycSurveyPage',
        params: { clientID: clientId },
        query: { context: 'In Person Annual Kyc Survey' },
      });
    },
    async startInPersonAkyc(client) {
      try {
        const dataAkyc = await fetchInProgressWorkflowsForClient(
          1,
          [client.client_id],
          'annual_kyc',
          'change_request',
        );

        if (dataAkyc.count === 0) {
          await createAnnualKycWorkflow(client.client_id, client.client_no_dataphile);
        }
        this.redirectToSurvey(client.client_id);
      } catch {
        this.$emit('show-toast', 'Something went wrong. Please try again, or contact us for more details.');
        this.loadClients(this.query.offset);
      }
    },
    async disableClientsWithOngoingChangeRequest() {
      // As soon as a client has a cm workflow in progress, we hide the enveloppe
      const dataCM = await fetchInProgressWorkflowsForClient(
        this.clients.length,
        this.clients.map(({ id }) => id),
        'client_maintenance',
      );

      // if the client has an annual_kyc workflow in_progress
      // and steps!=change_request are in_progress, we hide the enveloppe
      const dataAkyc = await fetchInProgressWorkflowsForClient(
        this.clients.length,
        this.clients.map(({ id }) => id),
        'annual_kyc',
        ALL_AKYC_CM_WORKFLOW_STEPS_EXCEPT_CHANGE_REQUEST,
      );

      const clientIds = this.clients.map(({ client_id: clientId }) => clientId);

      const results = (dataCM.results).concat((dataAkyc.results));

      results.forEach(({ client }) => {
        if (clientIds.includes(client.id)) {
          this.disabledClients.push(client.id);
        }
      });
    },
    getLastNotifiedDate(notifiedDate) {
      const displayDate = moment(notifiedDate).fromNow();
      return displayDate.includes('hours') ? 'Today' : displayDate;
    },
    openConfirmNotifyModal(clients) {
      this.notifyClients = clients;
      this.modalToShow = 'notify';
    },
    closeConfirmNotifyModal({ confirm }) {
      this.modalToShow = undefined;
      if (confirm) {
        this.notify(this.notifyClients);
      }
    },
    openConfirmMarkAsCompleteModal(clients) {
      this.notifyClients = clients;
      this.modalToShow = 'markAsComplete';
    },
    closeConfirmMarkAsCompleteModal({ confirm, selectedDate }) {
      this.modalToShow = undefined;
      if (confirm) {
        this.notifyClients[0].completed_date = selectedDate;
        this.markAsComplete(this.notifyClients);
      }
    },
    openConfirmInPersonAkycModal(clients) {
      this.notifyClients = clients;
      this.modalToShow = 'inPersonAkyc';
    },
    closeConfirmInPersonAkycModal({ confirm }) {
      this.modalToShow = undefined;
      if (confirm) {
        this.startInPersonAkyc(this.notifyClients[0]);
      }
    },
    isClientDisabled(clientId) {
      return this.disabledClients.includes(clientId);
    },
  },
  mounted() {
    this.loadClients(0);
  },
  watch: {
    notified(value) {
      if (value) {
        this.query.clientId = null;
        this.clientIdResetFilters = false;
      }
      this.query.notified = value;
      if (this.clientIdResetFilters === false) {
        this.resetTable();
        this.loadClients(0);
      }
    },
    status(value) {
      if (value) {
        this.query.clientId = null;
        this.clientIdResetFilters = false;
      }
      this.query.status = value;
      if (this.clientIdResetFilters === false) {
        this.resetTable();
        this.loadClients(0);
      }
    },
    team(value) {
      if (value) {
        this.query.clientId = null;
        this.clientIdResetFilters = false;
      }
      this.query.teamIds = [value];
      if (this.clientIdResetFilters === false) {
        this.resetTable();
        this.loadClients(0);
      }
    },
    async clientId(value) {
      if (value) {
        this.clientIdResetFilters = true;
        await this.$emit('reset-filters');
      }
      this.query.clientId = value;
      this.resetTable();
      this.loadClients(0);
    },
    selectedClients() {
      this.updateCheckboxes();
    },
    clients() {
      this.updateCheckboxes();
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-thead {
  font-size: 0.75rem;
  padding: 0.125rem !important;
}

.kyc-client-row {
  align-items: center;
  display: flex;
  height: 3.5rem;
  justify-content: space-between;
  margin: 0rem -1.5rem !important;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  & > :last-child {
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .row-header {
    flex: auto;
  }
}

dd {
  margin: 0 !important;
}

.small-badge {
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  line-height: 0.875rem !important;
  padding: 0.25rem 0.5rem !important;
  /* TODO either remove or add to StatusBadge component */
}

table:deep {
  thead.fixed-thead {
    height: 56px;
    tr > th[aria-sort] {
        background-image: none !important;
    }
  }
  /* TODO clean up after purposeful table component is updated */
  thead tr {
    font-size: 14px;
    font-weight: 500;
  }
  td, th {
    font-weight: inherit;
    font-size: 16px;
    font-family: 'Work Sans';
  }
}
.text-semibold {
  font-weight: 600;
}
</style>
