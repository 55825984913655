<template>
  <div>
    <template v-if="!clientLoaded">
      <v-layout align-center class="loader-layout">
        <v-flex>
          <pas-circular-loader :loadingMessage="`It'll Just Be A Moment...`"></pas-circular-loader>
        </v-flex>
      </v-layout>
    </template>
    <template v-else-if="clientLoaded">
      <PasPageHeader :path="`/clients/${activeClient.client.id}`">
        {{activeClient.client ? `${activeClient.client.display_name}'s` : ''}} Relationships
      </PasPageHeader>
      <div v-if="clientType === 'person'">
        <pas-tabs class="tabs-page-view">
          <template v-slot:tabTitle1 v-if="canViewClientRelationships">Relationships</template>
          <template v-slot:tabItem1 v-if="canViewClientRelationships">
            <template v-if="relationships.length">
            <span>The following provides an overview of {{activeClient.client ? `${activeClient.client.display_name}'s` : ""}} relationships:</span>
            <pas-list
              :columns="relationshipColumns"
              :items="relationships"
              :itemName="'relationships'"
              :loading="!clientLoaded"
              class="mt-5"
            >
              <template slot-scope="{ item }">
                <v-layout fill-height row :style="{paddingLeft: '23px'}">
                  <v-flex xs4>
                    <v-layout fill-height row align-center justify-start>
                      <router-link :to="`/clients/${item.client_2_id}`">
                        <pas-avatar
                          class="ml-2 mr-4"
                          :avatar="getAvatar('clients',item.client_2_id)"
                        ></pas-avatar>
                      </router-link>
                      <div class="strong-text underline-hover">
                        <router-link :to="`/clients/${item.client_2_id}`">{{item.display_name}}</router-link>
                      </div>
                    </v-layout>
                  </v-flex>
                  <v-flex xs4>
                    <v-layout fill-height row align-center justify-start>
                      <div class="p-text text-xs-center">{{item.relationship_type}}</div>
                    </v-layout>
                  </v-flex>
                  <v-flex xs3>
                    <v-layout align-center fill-height>
                      <v-chip v-if="item.trusted_contact_person === 'Y'">Trusted Contact Person</v-chip>
                    </v-layout>
                  </v-flex>
                  <v-flex xs1>
                    <v-layout fill-height justify-center align-center @click.stop="">
                      <pas-more-menu v-if="canEditClientRelationships" @click="$event(item.client_2_id)" :editOptions="editOptions"></pas-more-menu>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </template>
            </pas-list>
            </template>
          </template>
          <template v-slot:tabTitle2 v-if="canViewClientRelationships">Corporate Accounts</template>
          <template v-slot:tabItem2 v-if="canViewClientRelationships">
            <template v-if="corporateRoles.length">
            <span>The following provides an overview of {{activeClient.client ? `${activeClient.client.display_name}'s` : ""}} corporate accounts:</span>
            <CorporateRolesTable :clientLoaded="clientLoaded" />
            </template>
            <template v-else>
              <span>No matching corporate accounts</span>
            </template>
          </template>
          <template v-slot:tabTitle3 v-if="canViewBusinessRelationships">Beneficiaries</template>
          <template v-slot:tabItem3 v-if="canViewBusinessRelationships">
            <template v-if="beneficiaries.length">
            <span>
              The following provides an overview of beneficiaries of {{activeClient.client ? `${activeClient.client.display_name}'s` : ""}} accounts:
            </span>
            <pas-list
              :columns="beneficiariesColumns"
              :items="beneficiaries"
              :itemName="'beneficiaries'"
              :loading="!clientLoaded"
              class="mt-5"
            >
              <template slot-scope="{ item }">
                <v-layout fill-height row :style="{paddingLeft: '23px'}">
                  <v-flex xs4>
                    <v-layout fill-height row align-center justify-start>
                      <router-link :to="`/clients/${item.client}`">
                        <pas-avatar class="ml-2 mr-4" :avatar="getAvatar('clients',item.client)"></pas-avatar>
                      </router-link>
                      <div class="strong-text underline-hover">
                        <router-link :to="`/clients/${item.client}`">{{clients[item.client]}}</router-link>
                      </div>
                    </v-layout>
                  </v-flex>
                  <v-flex xs2>
                    <v-layout fill-height row align-center justify-start>
                      <div class="p-text">{{relationshipsObject[item.client] ? relationshipsObject[item.client] : '-'}}</div>
                    </v-layout>
                  </v-flex>
                  <v-flex xs2>
                    <v-layout fill-height row align-center justify-start>
                      <div class="p-text">{{getBeneficiaryType(item)}}</div>
                    </v-layout>
                  </v-flex>
                  <v-flex xs2>
                    <v-layout fill-height row align-center justify-start>
                      <div class="p-text">{{item.percentage? `${item.percentage * 100}%` : `-`}}</div>
                    </v-layout>
                  </v-flex>
                  <v-flex xs2>
                    <v-layout fill-height column justify-center>
                      <div class="p-text">{{accountNicknames[item.account].nickname}}</div>
                      <div class="light-text">{{accountNicknames[item.account].account_no_dataphile}}</div>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </template>
            </pas-list>
            </template>
            <template v-else>
              <span>No matching beneficiaries</span>
            </template>
          </template>
          <template v-slot:tabTitle4 v-if="canViewBusinessRelationships">Authorizations</template>
          <template v-slot:tabItem4 v-if="canViewBusinessRelationships">
            <template v-if="accountAuthorizations.length">
            <span>
              The following provides an overview of {{activeClient.client ? `${activeClient.client.display_name}'s` : ""}} account authorizations:
            </span>
            <pas-list
              :columns="authorizationColumns"
              :items="accountAuthorizations"
              :itemName="'beneficiaries'"
              :loading="!clientLoaded"
              class="mt-5"
            >
              <template slot-scope="{ item }">
                <v-layout fill-height row :style="{ paddingLeft: '23px' }">
                  <v-flex xs4>
                    <v-layout fill-height row align-center justify-start>
                      <router-link :to="`/clients/${item.client_2_id}`">
                        <pas-avatar
                          class="ml-2 mr-4"
                          :avatar="getAvatar('clients',item.client_2_id)"
                        ></pas-avatar>
                      </router-link>
                      <div class="strong-text underline-hover">
                        <router-link :to="`/clients/${item.client_2_id}`">{{item.display_name}}</router-link>
                      </div>
                    </v-layout>
                  </v-flex>
                  <v-flex xs4>
                    <v-layout fill-height row align-center justify-start>
                      <div class="p-text text-xs-center">{{item.relationship_type}}</div>
                    </v-layout>
                  </v-flex>
                  <v-flex xs4>
                    <v-layout fill-height row align-center justify-start>
                      <div class="p-text text-xs-center">{{item.authorization_type}}</div>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </template>
            </pas-list>
            </template>
            <template v-else>
              <span>No matching account authorizations</span>
            </template>
          </template>
        </pas-tabs>
      </div>
      <div v-else>
        <pas-tabs class="tabs-page-view">
          <template v-slot:tabTitle1 v-if="canViewClientRelationships">Roles</template>
          <template v-slot:tabItem1 v-if="canViewClientRelationships">
            <v-layout row align-center>
              <v-layout column>
                <span v-if="corporateRoles.length">
                  The following provides an overview of
                  {{ activeClient.client ? `${activeClient.client.display_name}'s` : ""}} roles:
                </span>
                <span v-else>No matching roles</span>
              </v-layout>
              <PasButton
                class="primary-btn"
                @click="isDefineRolesModalOpen = true"
              >
                Add Role
              </PasButton>
            </v-layout>
            <template v-if="corporateRoles.length">
              <CorporateRolesTable :clientLoaded="clientLoaded" />
            </template>
          </template>
        </pas-tabs>
      </div>
    </template>
    <define-roles
      :isOpen="isDefineRolesModalOpen"
      @close="isDefineRolesModalOpen = false"
      @save="saveRoles"
    />
    <add-edit-relationship
      v-if="canEditClientRelationships"
      :isOpen="addEditRelationship"
      :action="action"
      :relationship="relationship"
      @close="addEditRelationship = false"
    ></add-edit-relationship>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AddEditRelationship from 'Components/Shared/AddEditRelationship';
import DefineRoles from 'Components/Shared/DefineRoles';
import CorporateRolesTable from './CorporateRolesTable'
import { RELATIONS, REGISTERED_ACCOUNT_TYPES, ACCOUNTTYPE } from 'Services/constants';
import { getAvatar } from 'Services/helpers/files';

export default {
  name: 'client-relationships',
  components: {
    AddEditRelationship,
    CorporateRolesTable,
    DefineRoles,
  },
  props: {
    clientLoaded: Boolean,
  },
  data() {
    return {
      relationshipColumns: [
        { header: 'Name', size: 'xs4' },
        { header: 'Relationship Type', size: 'xs4' },
        { header: '', size: 'xs3' },
        { header: '', size: 'xs1' },
      ],
      corporateRolesColumns: [
        { header: 'Name', size: 'xs6' },
        { header: 'Role', size: 'xs6' },
      ],
      beneficiariesColumns: [
        { header: 'Name', size: 'xs4' },
        { header: 'Relationship', size: 'xs2' },
        { header: 'Type', size: 'xs2' },
        { header: 'Percentage', size: 'xs2' },
        { header: 'Account', size: 'xs2' },
      ],
      authorizationColumns: [
        { header: 'Name', size: 'xs4' },
        { header: 'Relationship Type', size: 'xs4' },
        { header: 'Authorization', size: 'xs4' },
      ],
      addEditRelationship: false,
      isDefineRolesModalOpen: false,
      beneficiaries: [],
      editOptions: [
        {
          title: 'View',
          id: 'view-relationships',
          onClick: (clientID) => this.$router.push(`/clients/${clientID}/`),
        },
        {
          title: 'Edit',
          id: 'edit-relationships',
          onClick: (clientID) => this.edit(clientID),
        },
        {
          title: 'Delete',
          id: 'delete-relationships',
          onClick: (clientID) => this.delete(clientID),
        },
      ],
      relationship: undefined,
      action: null,
      getAvatar,
    };
  },
  mounted() {
    this.fetchBeneficiaries();
  },
  computed: {
    ...mapGetters([
      'canViewClientRelationships',
      'canEditClientRelationships',
      'canViewBusinessRelationships',
    ]),
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    clientType() {
      return this.activeClient.client ? this.activeClient.client.client_type : '';
    },
    relationships() {
      return this.activeClient.relationships
        .map((r) => {
          let { relationship_type, relationship_id, inverse_relationship_id, trusted_contact_person } = r;
          const display_name = r.client_details[0] ? r.client_details[0].display_name : '';
          const client_2_id = r.client_details[0] ? r.client_details[0].id : '';
          relationship_type = RELATIONS[relationship_type];
          return {
            client_2_id,
            relationship_id,
            relationship_type, // ex "Parent"
            display_name, // client2 display name
            inverse_relationship_id, // client2 id
            trusted_contact_person,
          };
        });
    },
    accountAuthorizations() {
      return this.activeClient.relationships.map((r) => {
        const relationship_type = RELATIONS[r.relationship_type];
        const display_name = r.client_details[0] ? r.client_details[0].display_name : '';
        const client_2_id = r.client_details[0] ? r.client_details[0].id : '';
        const authorization_type = this.getAuthorizationType(r);
        return {
          client_2_id,
          relationship_type, // ex "Parent"
          display_name, // client2 display name
          authorization_type,
        };
      })
        .filter((r) => r.authorization_type);
    },
    jointClientIDs() {
      return this.investmentAccounts
        .filter((account) => account.joint_client_id[0])
        .map((account) => account.joint_client_id[0]);
    },
    corporateRoles() {
      return this.activeClient.corporate_roles.filter((r) => {
        const client = this.clientType === 'person' ? r.client : r.corporate_client;
        return client === this.activeClient.client.id;
      });
    },
    currentUser() {
      return this.$store.state.users.currentUser;
    },
    router() {
      return this.$router;
    },
    clients() {
      const clientList = {};
      this.$store.state.clients.list
        .forEach((client) => {
          const { id, display_name } = client;
          clientList[id] = display_name;
        });
      return clientList;
    },
    investmentAccounts() {
      return this.activeClient.investment_accounts.length ? this.activeClient.investment_accounts : [];
    },
    accountNicknames() {
      const accountNicknames = {};
      this.investmentAccounts
        .forEach((account) => {
          const { id, nickname, account_no_dataphile } = account;
          accountNicknames[id] = { nickname, account_no_dataphile };
        });
      return accountNicknames;
    },
    relationshipsObject() {
      const relationshipsObject = {};
      this.relationships
        .forEach((rel) => {
          const { client_2_id, relationship_type } = rel;
          relationshipsObject[client_2_id] = relationship_type;
        });
      return relationshipsObject;
    },
  },
  methods: {
    fetchBeneficiaries() {
      const registeredAccounts = this.investmentAccounts.filter((ia) => REGISTERED_ACCOUNT_TYPES.includes(ACCOUNTTYPE[ia.account_type_short]));
      Promise.all(
        registeredAccounts.map((ra) => {
          if (ra.account_type_short === '17' || ra.account_type_short === '18') return this.$store.dispatch('getRESPBeneficiaries', ra.id);
          return this.$store.dispatch('getBeneficiaries', ra.id);
        }),
      ).then((responses) => {
        this.beneficiaries = Array.prototype.concat.apply([],
          responses.filter((res) => res));
      })
        .catch((err) => {
          const text = 'Something went wrong, please refresh the page.';
          this.$store.dispatch('setSnackbar', { text, type: 'fail' });
          this.$store.dispatch('flipSnackbarSwitch');
        });
    },
    getAuthorizationType(r) {
      if (!r.view_accounts && r.trading_authorization && !r.full_authorization && r.power_of_attorney && !r.formal_poa) {
        return 'Trading Authorization';
      }
      if (!r.view_accounts && !r.trading_authorization && r.full_authorization && r.power_of_attorney && !r.formal_poa) {
        return 'Full Authorization';
      }
      if (!r.view_accounts && !r.trading_authorization && !r.full_authorization && r.power_of_attorney && r.formal_poa) {
        return 'Formal POA';
      }
      if (r.view_accounts && r.trading_authorization && !r.full_authorization && r.power_of_attorney && !r.formal_poa) {
        return 'View and Trade';
      }
      if (r.view_accounts && !r.trading_authorization && !r.full_authorization && !r.power_of_attorney && !r.formal_poa) {
        return 'View Only';
      }
      return '';
    },
    edit(clientID) {
      this.addEditRelationship = true;
      this.action = 'edit';
      this.relationship = this.relationships.filter((r) => r.client_2_id === clientID)[0];
    },
    delete(clientID) {
      this.errorText = '';
      const relationship = this.relationships.find((r) => r.client_2_id === clientID);
      if (this.jointClientIDs.includes(clientID)) {
        this.$store.dispatch('setSnackbar', { text: 'Cannot delete a relationship that shares a joint account', type: 'error', topic: '' });
        this.$store.dispatch('flipSnackbarSwitch');
        return;
      }
      this.processing = true;
      this.$store.dispatch('deleteRelationshipPair', [
        relationship.relationship_id,
        relationship.inverse_relationship_id,
      ])
        .then((responses) => {
          console.log('wow');
          this.$store.dispatch('setSnackbar', { text: 'The relationship has been deleted', type: 'success', topic: '' });
          this.$store.dispatch('flipSnackbarSwitch');
          this.$store.dispatch('fetchRelationships', this.activeClient.client.id);
        })
        .catch((err) => {
          this.$store.dispatch('setSnackbar', { text: 'Error in deleting relationship', type: 'error', topic: '' });
          this.$store.dispatch('flipSnackbarSwitch');
        });
    },
    viewClientProfile(itemID) {
      this.$router.push(`/clients/${itemID}`);
    },
    getBeneficiaryType(item) {
      const priorityType = {
        P: 'Primary',
        C: 'Contingent',
      };
      const roleType = {
        SA: 'Successor',
        SH: 'Successor',
        BN: 'Beneficiary',
      };

      return `${priorityType[item.priority]} ${roleType[item.role]}`;
    },
    saveRoles() {
      this.isDefineRolesModalOpen = false;
      this.$store.dispatch('fetchCorporateRoles', this.activeClient.client.id);
    },
  },
  watch: {
    investmentAccounts() {
      this.fetchBeneficiaries();
    },
    corporateRoles() {
      return this.activeClient.corporate_roles.filter((r) => {
        const client = this.clientType === 'person' ? r.client : r.corporate_client;
        return client === this.activeClient.client.id;
      });
    },
    currentUser() {
      return this.$store.state.users.currentUser;
    },
    router() {
      return this.$router;
    },
    clients() {
      const clientList = {};
      this.$store.state.clients.list.forEach((client) => {
        const { id, display_name } = client;
        clientList[id] = display_name;
      });
      return clientList;
    },
  },
};
</script>

<style lang="scss">
</style>
