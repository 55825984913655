<template>
  <Transfer
    :toInvestmentAccts="toInvestmentAccts"
    :fromInvestmentAccts="fromInvestmentAccts"
    :transferRequest="transferRequest"
    :transferMethods="transferMethods"
    :transferTypes="transferTypes"
    :requestSubtype="selectedRequestSubtype"
    :requestType="selectedRequestType"
    :docList="selectedRequestSubtype.docList"
    :optionalDocList="selectedRequestSubtype.optionalDocList"
    :relinquishingInstitutionOptions="relinquishingInstitutions"
    :errorAlertVisible="errorAlertVisible"
    :errorMessage="errorMessage"
    :currencyOptions="currencyOptions"
    :editMode="editMode"
    v-on:reviewTransfer="reviewTransfer"
    v-on:back="back"
    class="mt-5"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { postInternalTransferRequest, postExternalTransferRequest } from '../../../services/api/FundingVersion2';
import {
  REQUEST_TYPES,
  REQUEST_SUBTYPE_IDS,
  SHARE_DETAILS_CURRENCY_TYPES,
  TRANSFER_TYPE_IDS,
  transferOptions,
  transferMixed,
  dataFromValidatedRequest,
  formatAmountToDecimal,
  removeEmptyVals,
  parseBankDetails,
  dataFromValidatedAssetDetails,
} from '../../../services/helpers/funding';
import { parseTransferReviewPayload } from './reviewHelper';

export default {
  name: 'MakeTransfer',
  data() {
    return {
      transferRequest: {
        selectedToAcct: null, // pass account id only if needed
        selectedFromAcct: null, // pass account id only if needed
        startDate: null,
        note: null,
      },
      errorAlertVisible: false,
      errorMessage: 'Sorry, something went wrong. Try again in a few minutes.',
    };
  },
  computed: {
    ...mapGetters([
      'investmentAccts',
      'relatedClientAccts',
      'validatedRequest',
      'activeRequest',
      'editMode',
      'selectedRequestSubtype',
      'selectedRequestType',
      'marginAccts',
      'rolloverSavings',
      'rolloverIncome',
      'complexFundingFeatureFlag',
      'activeClient',
      'fundingDocuments',
      'currencyTypes',
      'relinquishingInstitutions',
    ]),
    transferMethods() {
      const methods = [...transferOptions.methods];
      if (this.selectedRequestSubtype.id === REQUEST_SUBTYPE_IDS.EXTERNAL_TRANSFER_IN) {
        methods.push(transferMixed);
      }
      return methods;
    },
    transferTypes() {
      const types = [...transferOptions.types];
      if (this.selectedRequestSubtype.id === REQUEST_SUBTYPE_IDS.INTERNAL_TRANSFER_ACCOUNT_ROLLOVER) {
        return types.filter((e) => e.id === TRANSFER_TYPE_IDS.FULL);
      }
      return types;
    },
    toInvestmentAccts() {
      switch (this.selectedRequestSubtype.id) {
        case REQUEST_SUBTYPE_IDS.INTERNAL_TRANSFER_ACCOUNT_ROLLOVER: return this.rolloverIncome;
        case REQUEST_SUBTYPE_IDS.INTERNAL_TRANSFER_DIFFERENT_CLIENTS: return this.relatedClientAccts;
        default: return this.investmentAccts;
      }
    },
    fromInvestmentAccts() {
      switch (this.selectedRequestSubtype.id) {
        case REQUEST_SUBTYPE_IDS.INTERNAL_TRANSFER_MARGIN_DRAW_DOWN: return this.marginAccts;
        case REQUEST_SUBTYPE_IDS.INTERNAL_TRANSFER_ACCOUNT_ROLLOVER: return this.rolloverSavings;
        default: return this.investmentAccts;
      }
    },
    activeRequestToAcctId() {
      if (this.activeRequest.to_account) {
        return this.activeRequest.to_account.account_id;
      }
      return null;
    },
    activeRequestFromAcctId() {
      if (this.activeRequest.from_account) {
        return this.activeRequest.from_account.account_id;
      }
      return null;
    },
    currencyOptions() {
      if (this.currencyTypes.length) {
        return this.currencyTypes.filter((e) => SHARE_DETAILS_CURRENCY_TYPES.includes(e.iso_code));
      }
      return [];
    },
  },
  methods: {
    async reviewTransfer(reviewPayload) {
      try {
        const data = parseTransferReviewPayload(reviewPayload, this.activeClient.client.id);

        let validatedPayload;
        if (this.selectedRequestSubtype.type === REQUEST_TYPES.INTERNAL_TRANSFER) {
          validatedPayload = await postInternalTransferRequest({ requestedScope: 'validate', body: data });
        } else if (this.selectedRequestSubtype.type === REQUEST_TYPES.EXTERNAL_TRANSFER) {
          validatedPayload = await postExternalTransferRequest({ requestedScope: 'validate', body: data });
        }
        validatedPayload.from_investment_account = validatedPayload.from_account;
        validatedPayload.to_investment_account = validatedPayload.to_account;
        validatedPayload.id = this.validatedRequest.id;

        this.$store.dispatch('setValidatedRequest', validatedPayload);
        this.$store.dispatch('setFundingDocuments', reviewPayload.documents);
        this.goToReviewCard();
      } catch (err) {
        this.errorAlertVisible = true;
        this.errorMessage = err.message;
      }
    },
    populateRequestData() {
      if (Object.keys(this.validatedRequest).length !== 0) {
        this.transferRequest = dataFromValidatedRequest(this.transferRequest, this.validatedRequest, this.fundingDocuments);
        if (this.validatedRequest.subtype !== REQUEST_SUBTYPE_IDS.EXTERNAL_TRANSFER_IN) {
          this.transferRequest.selectedFromAcct = this.validatedRequest.from_account
                                              || this.validatedRequest.investment_account_no;
        }
        if (this.validatedRequest.subtype !== REQUEST_SUBTYPE_IDS.EXTERNAL_TRANSFER_DONATION_REQUEST) {
          this.transferRequest.selectedToAcct = this.validatedRequest.to_account
                                           || this.validatedRequest.investment_account_no;
        }
        this.transferRequest.transferType = {
          transferMethod: this.validatedRequest.transfer_method,
          transferType: this.validatedRequest.transfer_type,
          amount: this.validatedRequest.amount
                || formatAmountToDecimal(this.validatedRequest.amount_cash)
                || formatAmountToDecimal(this.validatedRequest.amount_estimated),
          shareDetails: this.transferRequest.shareDetails,
        };
        this.transferRequest.runDate = this.validatedRequest.run_date;
        this.transferRequest.externalInformation = parseBankDetails(this.validatedRequest);
        this.transferRequest.isDonation = this.validatedRequest.is_donation;
        this.transferRequest.taxable = this.validatedRequest.taxable;
        this.transferRequest = removeEmptyVals(this.transferRequest);
        if (this.validatedRequest.assets) {
          this.transferRequest.assets = dataFromValidatedAssetDetails(this.validatedRequest.assets);
        }
        if (this.validatedRequest.transfer_remaining_assets_method) {
          this.transferRequest.transferRemainingAssetsMethod = this.validatedRequest.transfer_remaining_assets_method;
        }
      }
    },
    goToReviewCard() {
      this.$router.push({
        path: `/clients/${this.activeClient.client.id}/funding-v2/transfer/review/`,
      });
    },
    back() {
      this.$router.go(-1);
      this.$store.dispatch('setValidatedRequest', {});
      this.$store.dispatch('setActiveRequest', {});
      this.$store.dispatch('setFundingDocuments', []);
      this.$store.dispatch('setEditMode', false);
    },
  },
  mounted() {
    if (Object.keys(this.validatedRequest).length) {
      this.populateRequestData();
    }
  },
  created() {
    if (Object.keys(this.validatedRequest).length) {
      this.populateRequestData();
    }
  },
};
</script>

<style></style>
