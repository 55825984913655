<template>
  <v-card class="pa-3" :style="{ minHeight: '541px' }">
    <v-layout row>
      <v-flex>
        <h2 class="section-title mb-3">In progress workflows</h2>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
    <pas-list
      :columns="columns"
      :items="workflows"
      :min-height="false"
      :itemName="'workflows'"
    >
      <template slot-scope="{ item }">
        <v-layout fill-height row @click="viewWorkflow(item.id)">
          <!-- Workflow Type -->
          <v-flex xs4 pl-3>
            <v-layout fill-height row align-center justify-start>
              <span class="pas-list-item-text">{{workflowDisplayName(item.workflowType)}}</span>
            </v-layout>
          </v-flex>
          <!-- Status -->
          <v-flex xs4>
            <v-layout fill-height row align-center justify-start>
              <span class="pas-list-item-text">{{stepStatusName(item.workflowStatus)}}</span>
            </v-layout>
          </v-flex>
          <!-- Current Step -->
          <v-flex xs4>
            <v-layout fill-height row align-center justify-start>
              <span class="pas-list-item-text">{{getCurrentStep(item)}}</span>
            </v-layout>
          </v-flex>
          <!-- Updated at -->
          <v-flex xs2>
            <v-layout fill-height row align-center justify-end pr-3>
            <span class="pas-list-item-text">
              {{moment(item.workflowUpdatedAt).format('MMMM D, YYYY')}}
            </span>
            </v-layout>
          </v-flex>
        </v-layout>
      </template>
    </pas-list>
    <pas-button
      class="primary-btn wide-btn mt-3"
      @click="$router.push(`/workflows?clientId=${clientId}`)"
    >
      See all
    </pas-button>
  </v-card>
</template>

<script>
import _ from 'lodash';
import { WORKFLOW } from 'Services/constants';

export default {
  name: 'workflowsV2',
  props: {
    workflows: Array,
    workflowsCount: Number,
    clientId: Number,
  },
  data() {
    return {
      on: false,
      columns: [
        { header: 'Type', size: 'xs4' },
        { header: 'Status', size: 'xs4' },
        { header: 'Step', size: 'xs4' },
        { header: 'Updated', size: 'xs2' },
      ],
    };
  },
  methods: {
    workflowDisplayName(type) {
      return _.get(WORKFLOW.DISPLAY_NAMES, type, type);
    },
    stepStatusName(name) {
      return _.get(WORKFLOW.STATUS_NAMES, name, name);
    },
    getCurrentStep(workflow) {
      if (!workflow.steps) return '';
      const currentStep = _.head(workflow.steps);
      if (!currentStep) return '';
      return _.get(WORKFLOW.STEP_DISPLAY_NAMES, currentStep.stepName, '');
    },
    viewWorkflow(workflowID) {
      this.$router.push(`/workflow/${workflowID}`);
    },
  },
};
</script>

<style>
</style>
