<template>
  <pas-list
    :columns="columns"
    :items="corporateRoles"
    :itemName="'relationships'"
    :loading="!clientLoaded"
    class="mt-5"
  >
    <template slot-scope="{ item }">
      <v-layout fill-height row>
        <v-flex xs3>
          <v-layout fill-height row align-center justify-start>
            <router-link :to="`/clients/${getDetails(item).id}`">
              <pas-avatar
                class="ml-2 mr-4"
                :isCorporate="getDetails(item).client_type !== 'person'"
                :avatar="getAvatar('clients', getDetails(item).id)"
              />
            </router-link>
            <div class="strong-text underline-hover">
              <router-link :to="`/clients/${getDetails(item).id}`">{{ getDetails(item).display_name }}</router-link>
            </div>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout fill-height row align-center justify-start>
            <div class="p-text">{{ getRoleSummary(item) }}</div>
          </v-layout>
        </v-flex>
        <v-flex xs2>
          <v-layout fill-height row align-center justify-center>
            <v-chip v-if="item.authorized_signatory === true">Yes</v-chip>
            <v-chip v-else-if="item.authorized_signatory === false">No</v-chip>
            <div v-else>-</div>
          </v-layout>
        </v-flex>
        <v-flex xs2>
          <v-layout fill-height row align-center justify-center>
            <v-chip v-if="item.preferred_or_mandatory_signor === true">Yes</v-chip>
            <v-chip v-else-if="item.preferred_or_mandatory_signor === false">No</v-chip>
            <div v-else>-</div>
          </v-layout>
        </v-flex>
        <v-flex xs2>
          <v-layout fill-height row align-center justify-start>
            <div class="p-text">{{ item.percentage_ownership | percentage }}</div>
          </v-layout>
        </v-flex>
      </v-layout>
    </template>
  </pas-list>
</template>
<script>

import { getAvatar } from 'Services/helpers/files';
import { percentage } from 'Services/filters';
import { getRoleSummary } from 'Services/helpers/corporateRoles'

export default {
  name: 'CorporateRolesTable',
  filters: {
    percentage,
  },
  props: {
    clientLoaded: Boolean,
  },
  data() {
    return {
      columns: [
        { header: 'Name', size: 'xs3' },
        { header: 'Role', size: 'xs3' },
        { header: 'Authorized Signatory', size: 'xs2' },
        { header: 'Preferred/Mandatory Signor', size: 'xs2' },
        { header: 'Ownership', size: 'xs2' },
      ],
      getAvatar,
      getRoleSummary,
    };
  },
  computed: {
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    clientId() {
      return this.activeClient?.client?.id;
    },
    clientType() {
      return this.activeClient?.client?.client_type;
    },
    corporateRoles() {
      return this.activeClient.corporate_roles.filter((r) => {
        const client = this.clientType === 'person' ? r.client : r.corporate_client;
        return client === this.clientId;
      });
    },
    currentUser() {
      return this.$store.state.users.currentUser;
    },
  },
  methods: {
    getDetails(item) {
      if (this.clientType === 'person') {
        return item.corporate_client_details;
      }
      return item.client_details;
    },
  },
};
</script>

<style lang="scss">
</style>
