<!-- eslint-disable vue/max-len -->
<!--
Expects active client to have investment accounts and relationships populated
-->
<template>
  <v-dialog :value="isOpen" persistent fullscreen>
    <v-card :style="{ overflow: 'hidden !important' }">
      <PasFab class="close-btn" v-if="!onboardingSetupLoading">
        <v-icon @click="closeModal" class="close-icon">close</v-icon>
      </PasFab>
      <v-layout v-if="currentScreen !== 'addAccounts'">
        <div
          class="strong-text back-doodle"
          @click="
            surveyOptionsLoaded = false;
            currentScreen = 'addAccounts';
          "
          v-if="!onboardingSetupLoading"
        >
          &larr; Back
        </div>
      </v-layout>

      <v-container :class="{ 'accounts-container': !onboardingSetupLoading }">
        <template v-if="currentScreen === 'addAccounts'">
          <v-layout mb-3>
            <v-flex xs12 text-xs-center>
              <pas-page-title>Investment Account Setup</pas-page-title>
            </v-flex>
          </v-layout>
          <v-layout mb-4>
            <v-flex xs12 text-xs-center>
              <span v-if="!tempAccounts.length">
                Let's start with the most common types of accounts, you can
                always add more later. Or you can
                <span
                  @click="tempAccountsEdit = []"
                  class="action-text-lower"
                >start from scratch.</span
                >
              </span>
              <span v-else
              >Here's a list of the client's accounts in the process of
                confirmation</span
              >
            </v-flex>
          </v-layout>
          <v-layout mb-4 row wrap>
            <v-flex xs12 text-xs-center v-if="missingClientInfo">
              <pas-error-div :show="missingClientInfo ? true : false">
                {{`Please add a
                ${hasFeatureFlag('investor_portal') ? 'mobile/home' : 'mobile'}
                number and email to this client in order to open new accounts`}}
              </pas-error-div>
            </v-flex>
            <v-flex
              xs12
              :style="{ display: 'flex', 'justify-content': 'center' }"
              v-if="currentOnboardingWorkflow && clientIsNotActive"
            >
              <pas-notification-div
              >An onboarding workflow for this client already exists. Adding
                new accounts will restart this workflow.</pas-notification-div
              >
            </v-flex>
          </v-layout>

          <div :class="{ disabled: missingClientInfo }">
            <v-layout mt-1 mb-4 row nowrap class="accounts-table-header-layout">
              <!-- the table header-->
              <v-flex xs2 class="table-header" />
              <v-flex xs3 class="table-header">Type</v-flex>
              <v-flex xs3 class="table-header">Account Name</v-flex>
              <v-flex xs3 />
              <v-flex xs3 class="table-header">Currency</v-flex>
              <v-flex xs3 class="table-header">Add-ons</v-flex>
              <v-flex xs3 class="table-header" v-if="useColumnEnabled">Use</v-flex>
            </v-layout>
            <v-list pt-3 mb-5 class="pas-list-wrapper" data-testid="investment-accounts-list">
              <template v-for="(account, index) in tempAccountsEdit">
                <!-- the table contents-->
                <v-card
                  :key="`tile-${index}`"
                  :class="{
                    'my-2': true,
                    'list-card': true,
                    'no-pointer': !accountEditable(account),
                  }"
                >
                  <v-layout
                    row
                    fill-height
                    justify-center
                    align-center
                    class="accounts-row-layout"
                  >
                    <v-flex xs2 text-xs-center>
                      <pas-icon-circle
                        theme="grey"
                        @click="removeAccount(index)"
                        :style="{ margin: 'auto !important' }"
                      >
                        <v-icon>mdi-close</v-icon>
                      </pas-icon-circle>
                    </v-flex>
                    <v-flex xs3>
                      <v-layout row wrap>
                        <v-flex xs12 :style="{ height: '36px !important' }">
                          <pas-drop-down
                            :items="
                              account.id
                                ? [baseAccType(account)]
                                : adjustedBaseAccountTypes
                            "
                            class="inv-account-dropdown"
                            :value="baseAccType(account)"
                            @change="setBaseType(account, $event)"
                            :disabled="account.id ? true : false"
                          />
                        </v-flex>
                        <v-flex
                          v-if="baseAccType(account) === 'RESP' && !account.id"
                        >
                          <div class="action-text">
                            <span
                              v-if="
                                !account.beneficiaries_info
                                  || !account.beneficiaries_info.beneficiaries.length
                              "
                              class="action-text"
                              @click="
                                openModal('beneficiariesModalOpen', account)
                              "
                            >+ Specify Beneficiaries</span
                            >
                            <span
                              v-else
                              class="action-text"
                              @click="
                                openModal('beneficiariesModalOpen', account)
                              "
                            >Edit Beneficiaries</span
                            >
                          </div>
                        </v-flex>
                        <v-flex
                          v-if="
                            ['CASH ITF', 'JOINT CASH ITF'].includes(
                              baseAccType(account),
                            ) && !account.id
                          "
                        >
                          <div class="action-text">
                            <span
                              v-if="!account.nominee"
                              class="action-text"
                              @click="openModal('nomineeModalOpen', account)"
                            >+ Specify Nominee</span
                            >
                            <span
                              v-else
                              class="action-text"
                              @click="openModal('nomineeModalOpen', account)"
                            >Edit Nominee</span
                            >
                          </div>
                        </v-flex>
                        <v-flex
                          v-if="[
                            'RRIF',
                            'LIF',
                            'LRIF',
                            'PRIF',
                            'SPOUSAL RRIF',
                          ].includes(baseAccType(account))
                            && !account.id
                            && !age
                          "
                        >
                          <div class="action-text">
                            <span
                              class="action-text"
                              @click="openModal('ageModalOpen', account)"
                            >Confirm Age</span
                            >
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs3>
                      <PasEditableSpan
                        :value="account.nickname"
                        @editSaved="$set(account, 'nickname', $event)"
                        :rules="[
                          v.isRequired(),
                          (v) => /^(?!\s*$).+/.test(v) || 'Field is required',
                        ]"
                        :editIcon="''"
                        :disabled="account.id ? true : false"
                      />
                      <v-select
                        v-if="
                          custodian !== 'fcc'
                            && ['LIF', 'LRIF', 'PRIF', 'LRSP', 'LIRA'].includes(
                              baseAccType(account),
                            )
                        "
                        class="dropdown-label"
                        :items="jurisdictions"
                        :value="selectedJurisdiction"
                        :disabled="!!account.id"
                        label="Jurisdiction"
                        :placeholder="account.jurisdiction"
                        @input="jurisdictionSelected(account, $event)"
                        solo
                      />
                      <v-select
                        v-if="
                          custodian === 'fcc'
                            && ['LIF', 'LRIF', 'PRIF', 'LRSP', 'LIRA'].includes(
                              baseAccType(account),
                            )
                        "
                        class="dropdown-label"
                        :items="jurisdictionsFcc(baseAccType(account))"
                        :value="selectedJurisdiction"
                        :disabled="!!account.id"
                        label="Jurisdiction"
                        :placeholder="account.jurisdiction"
                        @input="jurisdictionSelected(account, $event)"
                        solo
                      />
                      <v-select
                        v-if="
                          ['JOINT CASH', 'JOINT CASH ITF'].includes(
                            baseAccType(account),
                          )
                        "
                        class="dropdown-label"
                        :items="relationships"
                        :value="selectedRelationship"
                        :disabled="!!account.id"
                        label="+ Add Account Holder"
                        :placeholder="getJointPlaceholderText(account)"
                        @input="relationshipSelected(account, $event)"
                        solo
                      />
                      <template v-if="baseAccType(account) === 'RESP'">
                        <v-layout row>
                          <v-flex :disabled="!!account.id" v-if="!!account.id || account.joint_indicator">
                            <div
                              class="action-text"
                              v-if="spousalRelationships.length !== 0"
                              @click="setJointSubscriber(account)"
                            >
                              {{ getJointSubscriberText(account.joint_client_id[0]) }}
                            </div>
                          </v-flex>
                          <v-flex class="joint-subscriber" v-else>
                            <pas-drop-down
                              :items="spousalRelationships"
                              :hideSelected="false"
                              :value="account.joint_client_id && account.joint_client_id[0]"
                              @change="setJointSubscriber(account, $event)"
                              class="inv-account-dropdown"
                              id="resp-joint-subscriber"
                              label="Add Joint Subscriber"
                            />
                          </v-flex>
                          <v-flex>
                            <pas-icon-circle
                              v-if="account.joint_indicator && account.tempID"
                              theme="grey"
                              @click="setJointSubscriber(account)"
                            >
                              <v-icon>mdi-close</v-icon>
                            </pas-icon-circle>
                          </v-flex>
                        </v-layout>
                      </template>
                    </v-flex>

                    <v-flex xs3>
                      <template
                        v-if="
                          ['JOINT CASH', 'JOINT CASH ITF'].includes(
                            baseAccType(account),
                          ) || (['RESP'].includes(baseAccType(account)) && account.joint_indicator)
                        "
                      >
                        <pas-button-toggle
                          :value="account.joint_type == 1 ? 0 : 1"
                          @input="
                            $event == 0
                              ? (account.joint_type = 1)
                              : (account.joint_type = 2)
                          "
                          :class="{ 'pas-toggle': true, flexColumn: true }"
                          :mandatory="true"
                          :disabled="account.id ? true : false"
                        >
                          <v-btn
                            class="px-4"
                            :disabled="account.id ? true : false"
                            flat
                          >Tenants In Common</v-btn
                          >
                          <v-btn
                            class="px-4"
                            :disabled="account.id ? true : false"
                            flat
                          >Right Of Survivorship</v-btn
                          >
                        </pas-button-toggle>
                      </template>
                      <template
                        v-if="
                          [
                            'RRIF',
                            'LIF',
                            'LRIF',
                            'PRIF',
                            'SPOUSAL RRIF',
                          ].includes(baseAccType(account)) && !account.id
                        "
                      >
                        <span
                          v-if="!account.rif_details"
                          class="action-text"
                          @click="openModal('rifModalOpen', account)"
                        >Add Payment Instructions</span
                        >
                        <span
                          v-else
                          class="action-text"
                          @click="openModal('rifModalOpen', account)"
                        >Edit Payment Instructions</span
                        >
                      </template>
                    </v-flex>
                    <v-flex xs3>
                      <pas-button-toggle
                        :value="account.currency === 'CAD' ? 0 : 1"
                        @input="
                          $event === 0
                            ? (account.currency = 'CAD')
                            : (account.currency = 'USD')
                        "
                        :class="{ 'pas-toggle': true, flexColumn: true }"
                        :mandatory="true"
                        :disabled="account.id ? true : false"
                      >
                        <v-btn
                          :disabled="account.id ? true : false"
                          class="px-4"
                          flat
                        >CAD</v-btn
                        >
                        <v-btn
                          :disabled="
                            account.disableUSD || account.id ? true : false
                          "
                          class="px-4"
                          flat
                        >USD</v-btn
                        >
                      </pas-button-toggle>
                    </v-flex>
                    <v-flex xs3 v-if="baseAccType(account) !== 'RESP'">
                      <pas-button-toggle
                        v-if="isFirm(client.client.firm_id) === 'mil'"
                        :multiple="true"
                        :valueArray="[
                          account.margin_status ? 0 : null,
                          account.options_status ? 1 : null,
                          account.icp_indicator ? 2 : null,
                        ]"
                        @input="setAccountOptions($event, account)"
                        :class="{ 'pas-toggle': true, flexColumn: true }"
                        :disabled="account.id ? true : false"
                      >
                        <v-btn
                          class="px-2"
                          :disabled="
                            !!account.id || isRegisteredAccount(account)
                          "
                          flat
                        >Margin</v-btn
                        >
                        <v-btn
                          class="px-2"
                          :disabled="!!account.id"
                          flat
                        >Options</v-btn
                        >
                        <v-btn
                          class="px-2"
                          :disabled="!!account.id"
                          flat
                        >ICP</v-btn
                        >
                      </pas-button-toggle>
                      <pas-button-toggle
                        v-else
                        :multiple="true"
                        :valueArray="[
                          account.margin_status ? 0 : null,
                          account.options_status ? 1 : null,
                        ]"
                        @input="setAccountOptions($event, account)"
                        :class="{ 'pas-toggle': true, flexColumn: true }"
                        :disabled="account.id ? true : false"
                      >
                        <v-btn
                          class="px-4"
                          :disabled="
                            !!account.id || isRegisteredAccount(account)
                          "
                          flat
                        >Margin</v-btn
                        >
                        <v-btn
                          class="px-4"
                          :disabled="!!account.id"
                          flat
                        >Options</v-btn
                        >
                      </pas-button-toggle>
                      <v-select
                        v-if="account.icp_indicator && !account.id"
                        class="dropdown-label icp-mandate"
                        :items="Object.values(ICP_MANDATENAMES)"
                        :disabled="!!account.id"
                        label="+ Select ICP Mandate "
                        @input="icpMandateSelected(account, $event)"
                        solo
                        chips
                      />
                      <pas-button-toggle
                        v-if="account.icp_indicator && account.id"
                        :class="{ 'pas-toggle': true, flexColumn: true }"
                        :disabled="true"
                      >
                        <v-btn class="px-2 mt-1" :disabled="true" flat>{{
                          ICP_MANDATENAMES[account.mandate_name]
                            || "No mandate code"
                        }}</v-btn>
                      </pas-button-toggle>
                    </v-flex>

                    <v-flex xs3 v-else>
                      <pas-button-toggle
                        v-if="isFirm(client.client.firm_id) === 'mil'"
                        :multiple="false"
                        :value="
                          account.resp_type
                            || (ACCOUNT_SUB_TYPE[account.subtype] === 'Family RESP'
                              ? 1
                              : 0)
                        "
                        @input="setRespType(account, $event)"
                        :class="{ 'pas-toggle': true, flexColumn: true }"
                      >
                        <v-btn
                          class="px-2"
                          :disabled="account.id ? true : false"
                          flat
                        >Individual</v-btn
                        >
                        <v-btn
                          class="px-2"
                          :disabled="account.id ? true : false"
                          flat
                        >Family</v-btn
                        >
                        <pas-button-toggle
                          v-if="isFirm(client.client.firm_id) === 'mil'"
                          :multiple="false"
                          :value="account.icp_indicator ? 0 : 1"
                          @input="setRespICP(account)"
                          :class="{ 'pas-toggle': true, flexColumn: true }"
                          :disabled="account.id ? true : false"
                        >
                          <v-btn
                            class="px-2"
                            :disabled="account.id ? true : false"
                            flat
                          >ICP</v-btn
                          >
                        </pas-button-toggle>
                      </pas-button-toggle>

                      <pas-button-toggle
                        v-else
                        :multiple="false"
                        :value="
                          account.resp_type
                            || (ACCOUNT_SUB_TYPE[account.subtype] === 'Family RESP'
                              ? 1
                              : 0)
                        "
                        @input="setRespType(account, $event)"
                        :class="{ 'pas-toggle': true, flexColumn: true }"
                      >
                        <v-btn
                          class="px-4"
                          :disabled="account.id ? true : false"
                          flat
                        >Individual</v-btn
                        >
                        <v-btn
                          class="px-4"
                          :disabled="account.id ? true : false"
                          flat
                        >Family</v-btn
                        >
                      </pas-button-toggle>
                      <v-select
                        v-if="account.icp_indicator && !account.id"
                        class="dropdown-label icp-mandate"
                        :items="Object.values(ICP_MANDATENAMES)"
                        :disabled="!!account.id"
                        label="+ Select ICP Mandate "
                        @input="icpMandateSelected(account, $event)"
                        solo
                        chips
                      />
                      <pas-button-toggle
                        v-if="account.icp_indicator && account.id"
                        :class="{ 'pas-toggle': true, flexColumn: true }"
                        :disabled="true"
                      >
                        <v-btn class="px-2 mt-1" :disabled="true" flat>{{
                          ICP_MANDATENAMES[account.mandate_name]
                            || "No mandate code"
                        }}</v-btn>
                      </pas-button-toggle>
                    </v-flex>
                    <v-flex xs3 v-if="useColumnEnabled">
                      <pas-drop-down
                        :items="accountUses"
                        class="inv-account-dropdown"
                        :value="account.use_of_account"
                        @change="setAccountUse(account, $event)"
                        :disabled="account.id ? true : false"
                        data-testid="investment-account-use"
                      />
                    </v-flex>
                  </v-layout>
                </v-card>
              </template>
            </v-list>
            <v-layout mt-3 ref="accountLayout">
              <!-- add account layout -->
              <template v-if="!addingAccount">
                <v-flex text-xs-left>
                  <v-layout
                    row
                    justify-start
                    class="action-text"
                    :style="{ maxWidth: '200px' }"
                    @click="toggleAddAccount"
                  >
                    <v-flex :style="{ maxWidth: '30px !important' }">
                      <br />
                      <br />
                      <pas-icon-circle
                        theme="pas-transparent"
                        class="small-icon-circle"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </pas-icon-circle>
                    </v-flex>
                    <v-flex>
                      <br />
                      <br />
                      <span class="action-text">Add Account</span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </template>
              <template v-else>
                <v-flex xs12 @click="resetIdleTimer">
                  <v-layout>
                    <v-flex xs12>
                      <br />
                      <br />
                      <h4 class="line-div">
                        <span>Choose from</span>
                      </h4>
                    </v-flex>
                  </v-layout>
                  <v-layout row data-testid="investment-accounts-picker">
                    <v-flex>
                      <v-layout row mt-3>
                        <template v-for="accountType in visibleAccountTypes">
                          <v-tooltip
                            :disabled="!eligibilityMessage(accountType)"
                            :key="`${accountType}-type`"
                            top
                          >
                            <v-flex
                              slot="activator"
                              :class="{
                                'choose-from-div': true,
                                disabled: !accountEligibility(accountType),
                                'account-flex': true,
                              }"
                              @click="addAccount(accountType)"
                            >{{ accountType }}</v-flex
                            >
                            <span>{{ eligibilityMessage(accountType) }}</span>
                          </v-tooltip>
                        </template>
                        <v-spacer />
                        <v-flex text-xs-right>
                          <pas-pagination
                            class="hide-pagination-items"
                            @input="page = $event"
                            :length="paginationLength"
                            :value="page"
                          />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </template>
            </v-layout>
          </div>

          <v-layout v-if="continueError">
            <v-flex xs12 text-xs-center>
              <div class="error--text">
                <span class="v-messages__message">{{ continueError }}</span>
              </div>
            </v-flex>
          </v-layout>
          <v-layout mt-5>
            <!-- cancel continue buttons -->
            <pas-button
              @click="closeModal"
              theme="inverted"
              class="px-4"
            >Cancel</pas-button
            >
            <v-spacer />
            <pas-button
              v-if="clientIsNotActive"
              theme="primary"
              class="px-4"
              @click="continueClick"
              :disabled="missingClientInfo || !validateAccounts()"
              data-testid="investment-accounts-continue-btn"
            >Continue</pas-button
            >
            <pas-button
              v-else
              theme="primary"
              class="px-4"
              @click="beforeCreateNewAccount"
              :disabled="missingClientInfo || isCreating"
            >Done</pas-button
            >
          </v-layout>
        </template>

        <template v-if="currentScreen === 'addBeneficiary'">
          <pas-page-title class="text-xs-center xs12"
          >Add Beneficiaries to Registered Accounts</pas-page-title
          >
          <v-layout column>
            <v-flex mb-4 xs12 text-xs-center>
              <span
              >Specify the beneficiaries for the registered account(s)</span
              >
            </v-flex>
            <v-flex mb-4 xs12 text-xs-center>
              <span>
                If a beneficiary is not yet in the advisor center you can
                <span
                  @click="relationshipModalOpen = true"
                  class="action-link underline"
                >add a new relationship.</span
                >
              </span>
            </v-flex>
          </v-layout>

          <v-layout column>
            <template v-for="(account, index) in tempAccountsEdit">
              <v-flex
                :key="account.tempID"
                offset-xs3
                v-if="isRegisteredAccount(account) && !account.id"
              >
                <v-layout column>
                  <v-flex mt-4 mb-2>
                    <v-layout align-end>
                      <v-flex xs1>
                        <span class="strong-text">{{ account.nickname }}</span>
                      </v-flex>
                      <v-flex
                        v-if="firstRegisteredAccount() === account.tempID"
                      />
                    </v-layout>
                  </v-flex>

                  <v-checkbox
                    :color="primaryColor"
                    v-model="account.generateEstate"
                    :label="'Generate forms for Estate as sole beneficiary'"
                    v-if="generateEstateCheck(account)"
                  />

                  <v-flex ml-3 mt-3 mb-2>
                    <span>PRIMARY BENEFICIARIES</span>
                  </v-flex>

                  <v-flex
                    v-for="b in account.beneficiaries.filter(
                      (bene) => bene.is_estate && bene.priority === 'P',
                    )"
                    :key="b.id ? b.id : b.tempID"
                    ml-3
                  >
                    <v-layout v-if="!hasPrimaryBeneficiariesWithRole(account, 'SH') && !hasPrimaryBeneficiariesWithRole(account, 'SA')">
                      <v-flex shrink mb-2>
                        <pas-input-field
                          :value="b.percentage"
                          :suffix="'%'"
                          :style="{ width: '105px' }"
                          :readonly="true"
                        />
                      </v-flex>
                      <v-flex pl-3 pt-3>
                        <span>{{ client.client.display_name }}'s estate</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-flex
                    v-for="b in account.beneficiaries.filter(
                      (bene) => !bene.is_estate && bene.priority === 'P',
                    )"
                    :key="b.id ? b.id : b.tempID"
                    ml-3
                  >
                    <v-layout>
                      <v-flex shrink mb-2>
                        <pas-icon-circle
                          theme="grey"
                          class="delete-beneficiary"
                          @click="
                            removeBeneficiary(
                              account,
                              index,
                              b.id ? b.id : b.tempID,
                            )
                          "
                        >
                          <v-icon>mdi-close</v-icon>
                        </pas-icon-circle>
                        <pas-input-field
                          :value="b.percentage"
                          @input="
                            updateBeneficiaryPercentage(account, b, $event)
                          "
                          :suffix="'%'"
                          :style="{ width: '105px' }"
                          :masked="Number(b.percentage[0]) === 1 ? '###' : '##'"
                        />
                      </v-flex>
                      <v-flex shrink ml-3 pt-3>
                        <div
                          class="action-text"
                          @click="toggleBeneficiaryDropdown(b)"
                        >
                          {{ getBeneficiaryLabelText(b) }}
                        </div>
                        <div class="combo-menu" v-if="jointDropdown === b">
                          <pas-autocomplete
                            :items="
                              ['SA', 'SH'].includes(b.role)
                                ? spousalRelationships
                                : relationships
                            "
                            :value="selectedRelationship"
                            :prependInnerIcon="`search`"
                            :autofocus="true"
                            @blur="jointDropdown = ''"
                            @input="beneficiarySelected(b, $event)"
                            :noDataText="`No client relationships`"
                            :smallChips="true"
                          />
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-flex ml-3 mt-2>
                    <v-layout row>
                      <v-flex>
                        <span
                          @click="
                            addBeneficiary(
                              account.id || account.tempID,
                              'P',
                              'BN',
                            )
                          "
                          class="action-link"
                          v-if="
                            !hasPrimaryBeneficiariesWithRole(account, 'SH')
                              && !hasPrimaryBeneficiariesWithRole(account, 'SA')
                          "
                        >Add another Beneficiary</span
                        >
                        <span
                          v-if="
                            !hasNonEstatePrimaryBeneficiaries(account)
                              && [
                                'TFSA',
                                'RRIF',
                                'RRIF-S',
                                'PRIF',
                                'LRIF',
                                'LIF',
                              ].some((type) => hasAccountType(account, type))
                          "
                        >
                          or
                          <span
                            @click="
                              addBeneficiary(
                                account.id || account.tempID,
                                'P',
                                'SH',
                              )
                            "
                            class="action-link"
                            v-if="hasAccountType(account, 'TFSA')"
                          >add a Successor Holder</span
                          >
                          <span
                            @click="
                              addBeneficiary(
                                account.id || account.tempID,
                                'P',
                                'SA',
                              )
                            "
                            class="action-link"
                            v-else
                          >add a Successor Annuitant</span
                          >
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex ml-3 mt-2 v-if="hasPrimaryBeneficiaries(account)">
                    <v-layout row>
                      <v-flex>
                        <span>
                          <span
                            @click="
                              addBeneficiary(
                                account.id || account.tempID,
                                'C',
                                'BN',
                              )
                            "
                            class="action-link"
                            v-if="(hasPrimaryBeneficiariesWithRole(account, 'BN') || hasPrimaryBeneficiariesWithRole(account, 'SA') || hasPrimaryBeneficiariesWithRole(account, 'SH')) && !contingentBeneficiaryCheck(account) && custodian !== 'fcc'"
                          >Add Contingent Beneficiaries</span
                          >
                          <span
                            @click="
                              addBeneficiary(
                                account.id || account.tempID,
                                'C',
                                'SH',
                              )
                            "
                            class="action-link"
                            v-if="hasPrimaryBeneficiariesWithRole(account, 'SH') && custodian !== 'fcc'"
                          >
                            Add a Contingent Successor Holder
                          </span>
                          <span
                            @click="
                              addBeneficiary(
                                account.id || account.tempID,
                                'C',
                                'SA',
                              )
                            "
                            class="action-link"
                            v-if="hasPrimaryBeneficiariesWithRole(account, 'SA') && custodian !== 'fcc'"
                          >Add a Contingent Successor Annuitant</span
                          >
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-flex v-if="validateBeneficiaryText" ml-3 mt-1>
                    <span class="error--text">{{
                      validateBeneficiary(account, "P")
                    }}</span>
                  </v-flex>

                  <template v-if="contingentBeneficiaryCheck(account)">
                    <v-flex ml-3 mt-4 mb-2>
                      <span>CONTINGENT BENEFICIARIES</span>
                      <br />
                      <span class="light-text">
                        If the percentage allocation to the contingent beneficiary(ies)
                        does not total 100%, the residual amount will be allocated to the estate.
                      </span>
                    </v-flex>
                    <v-flex
                      v-for="(b) in account.beneficiaries.filter(
                        (bene) => !bene.is_estate && bene.priority === 'C',
                      )"
                      :key="b.id"
                      ml-3
                    >
                      <v-layout>
                        <v-flex shrink mb-2>
                          <pas-icon-circle
                            theme="grey"
                            class="delete-beneficiary"
                            @click="
                              removeBeneficiary(
                                account,
                                index,
                                b.id ? b.id : b.tempID,
                              )
                            "
                          >
                            <v-icon>mdi-close</v-icon>
                          </pas-icon-circle>
                          <pas-input-field
                            :value="b.percentage"
                            @input="
                              updateBeneficiaryPercentage(account, b, $event)
                            "
                            :suffix="'%'"
                            :style="{ width: '105px' }"
                            :masked="Number(b.percentage[0]) === 1 ? '###' : '##'"
                          />
                        </v-flex>
                        <v-flex shrink ml-3 pt-3>
                          <div
                            class="action-text"
                            @click="toggleBeneficiaryDropdown(b)"
                          >
                            {{ getBeneficiaryLabelText(b) }}
                          </div>
                          <div class="combo-menu" v-if="jointDropdown === b">
                            <pas-autocomplete
                              :items="
                                ['SA', 'SH'].includes(b.role)
                                  ? spousalRelationships
                                  : relationships
                              "
                              :value="selectedRelationship"
                              :prependInnerIcon="`search`"
                              :autofocus="true"
                              @blur="jointDropdown = ''"
                              @input="beneficiarySelected(b, $event)"
                              :noDataText="`No client relationships`"
                              :smallChips="true"
                            />
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex v-if="validateBeneficiaryText" ml-3 mt-1>
                      <span class="error--text">{{
                        validateBeneficiary(account, "C")
                      }}</span>
                    </v-flex>

                    <v-flex ml-3 mt-2>
                      <v-layout row>
                        <v-flex shrink mr-1>
                          <span
                            v-if="
                              hasContingentBeneficiariesWithRole(account, 'BN')
                            "
                            class="action-link"
                            @click="
                              addBeneficiary(
                                account.id || account.tempID,
                                'C',
                                'BN',
                              )
                            "
                          >Add another contingent beneficiary</span
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </template>
                </v-layout>
              </v-flex>
            </template>
          </v-layout>

          <!-- Generate forms for Estate as sole beneficiary  -->

          <v-layout column>
            <v-flex mt-4 xs12 text-xs-center>
              <pas-error-div :show="beneficiaryError">
                <ul class="error-list">
                  <li v-for="(error, index) in beneficiaryErrorText" :key="index" class="error-item">
                    {{ error }}
                  </li>
                </ul>
              </pas-error-div>
            </v-flex>
            <v-flex mt-4 pt-4 offset-xs8>
              <pas-button
                theme="primary"
                class="px-4"
                @click="
                  validateBeneficiaries()
                "
                :disabled="missingClientInfo"
                data-testid="beneficiaries-confirm-continue-btn"
              >Confirm & Continue</pas-button
              >
            </v-flex>
          </v-layout>
        </template>

        <template v-if="currentScreen === 'addToFeeGroup'">
          <AddToFeeGroup @continueClick="continueNext" />
        </template>

        <template v-if="currentScreen === 'chooseSurveyOption'">
          <!-- onboarding with investor portal trigger screen -->
          <TriggerOnboardingIP
            v-if="hasFeatureFlag('investor_portal')"
            @saveTempAccts="saveTempAccts"
            @triggerOnboardingWorkflowIP="triggerOnboardingWorkflowIP"
            :onboardingSetupLoading="onboardingSetupLoading"
            :surveyOptionsLoaded="surveyOptionsLoaded"
            :savingAccounts="savingAccounts"
            :surveyFilled="retriggerOnboardingEnabled"
            :registered="client.IPInviteStatus === 2 && retriggerOnboardingEnabled"
          />
          <!-- onboarding with survey engine trigger screen -->
          <trigger-onboarding
            v-else
            @triggerInPerson="triggerInPerson"
            @triggerByEmail="triggerByEmail"
            :onboardingSetupLoading="onboardingSetupLoading"
            :surveyOptionsLoaded="surveyOptionsLoaded"
          />
        </template>

        <template v-if="currentScreen === 'chooseSaveOption'">
          <SaveOptions
            @createNewAccount="createNewAccount"
            @saveTempAccts="saveTempAccts"
          />
        </template>

        <template v-if="currentScreen === 'surveyEmailSent'">
          <v-layout>
            <v-flex xs12 text-xs-center>
              <v-img
                :src="EmailSvg"
                height="200"
                width="200"
                :style="{ margin: 'auto !important' }"
              />
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12 text-xs-center>
              <span class="light-text"
              >An e-mail has been sent to the client</span
              >
            </v-flex>
          </v-layout>
        </template>

        <template v-if="currentScreen === 'onboardingWorkflowCreated'">
          <v-layout>
            <v-flex xs12 text-xs-center>
              <v-img
                :src="EmailSvg"
                height="200"
                width="200"
                :style="{ margin: 'auto !important' }"
              />
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12 text-xs-center>
              <span class="light-text"
              >A new onboarding workflow is created</span
              >
            </v-flex>
          </v-layout>
        </template>

        <template v-if="currentScreen === 'surveyEngineLaunched'">
          <v-layout>
            <v-flex xs12 text-xs-center>
              <v-img
                :src="InPersonSvg"
                height="200"
                width="200"
                :style="{ margin: 'auto !important' }"
              />
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12 text-xs-center>
              <span class="light-text"
              >The onboarding survey has been launched in a few
                seconds...</span
              >
            </v-flex>
          </v-layout>
        </template>
      </v-container>
    </v-card>
    <v-dialog :value="RESPDialog" width="400px" height="400px" persistent>
      <v-card class="pa-4">
        <v-card-text>Do you wish to apply for government grants?</v-card-text>
        <v-spacer />
        <v-card-actions>
          <v-layout align-center justify-end row fill-height>
            <v-flex shrink>
              <pas-button
                class="primary-btn"
                @click="addTrustee(1)"
              >Yes</pas-button
              >
            </v-flex>
            <v-flex shrink>
              <pas-button
                class="inverted-btn"
                @click="addTrustee(0)"
              >No</pas-button
              >
            </v-flex>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      :value="isEditingSubscriber"
      width="400px"
      height="400px"
      persistent
    >
      <v-card class="pa-4">
        <v-card-text>
          Fidelity Clearing Canada needs to know the gender of any Subscriber on
          RESP accounts. Please select a gender for
          {{ subscriber.display_name }}.
        </v-card-text>
        <v-radio-group
          v-model="subscriber.gender"
          :rules="[v.isRequired()]"
          class="fcc-gender-radios"
        >
          <v-layout align-start justify-center row>
            <v-radio
              v-for="(item, i) in FCC_GENDERS"
              :key="i"
              color="#c48f3e"
              :label="item"
              :value="item"
            />
          </v-layout>
        </v-radio-group>
        <v-layout align-center justify-end row fill-height>
          <v-flex shrink>
            <pas-button
              @click="saveSubscriber(subscriber)"
              :disabled="!subscriber.gender"
            >Submit</pas-button
            >
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog :value="RSPDialog" width="400px" height="400px" persistent>
      <v-card class="pa-4">
        <v-card-text v-if="RSPDialog && RSPDialog.type === 'I'"
        >You are opening an RSP account. By proceeding you confirm your spouse
          will not be making contributions to the RSP account.</v-card-text
        >
        <v-card-text v-if="RSPDialog && RSPDialog.type === 'S'"
        >You are opening a Spousal RSP account. By proceeding you confirm your
          spouse may make contributions to this account.</v-card-text
        >
        <v-spacer />
        <v-card-actions>
          <v-layout align-center justify-end row fill-height>
            <v-flex shrink>
              <pas-button
                class="inverted-btn"
                @click="
                  setRSPSpousalInfoDialog(RSPDialog.index);
                  RSPDialog = null;
                "
              >Submit</pas-button
              >
            </v-flex>
            <v-flex shrink>
              <pas-button
                class="primary-btn"
                @click="
                  removeAccount(RSPDialog && RSPDialog.index);
                  RSPDialog = null;
                "
              >Cancel</pas-button
              >
            </v-flex>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- popup to confirm spouse birthday and SIN  -->
    <v-dialog
      :value="RSPSpousalInfoDialog"
      width="400px"
      height="400px"
      persistent
    >
      <v-card class="pa-4">
        <v-card-text
        >The birth date and SIN (Social Insurance Number) for the client’s
          spouse are required to open a spousal RSP account. Please confirm the
          client’s spouse{{
            spousalRelationships[0] ? `, ${spousalRelationships[0].text}'s` : ""
          }}
          information below.</v-card-text
        >
        <v-spacer />
        <v-card-actions>
          <v-layout align-center justify-end row fill-height>
            <v-form lazy-validation ref="spousalInfoForm" @submit.prevent="">
              <v-layout>
                <v-flex mr-3 class="max-width-50">
                  <div class="strong-text form-label">SIN</div>
                  <PasInputField
                    v-model="spousalInfo.SIN"
                    placeholder="Social insurance number"
                    :rules="[
                      v.isNumeric(),
                      v.specifiedChars(9),
                      v.isSINValid(),
                    ]"
                    :maxLength="9"
                  />
                </v-flex>
                <v-flex class="max-width-50">
                  <div class="strong-text form-label">Date of Birth</div>
                  <PasDatepicker
                    :value="spousalInfo.birthDate"
                    @input="spousalInfo.birthDate = $event"
                    iconLocation="before"
                    label="Select Date"
                    :key="`date-field`"
                  />
                </v-flex>
              </v-layout>
              <div :style="{ 'margin-top': '32px', 'text-align': 'right' }">
                <pas-button
                  class="inverted-btn"
                  @click="
                    removeAccount(
                      RSPSpousalInfoDialog && RSPSpousalInfoDialog.index,
                    );
                    RSPSpousalInfoDialog = null;
                  "
                >Cancel</pas-button
                >
                <pas-button
                  class="primary-btn"
                  @click="updateSpousalInfo()"
                >Submit</pas-button
                >
              </div>
            </v-form>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <setup-rif-payment
      :isOpen="rifModalOpen"
      @close="rifModalOpen = false"
      :key="`rif-details-modal`"
      @openModal="$emit('openModal', $event)"
      :account="modalAccount"
      @save="$set(modalAccount, 'rif_details', $event)"
    />
    <SpecifyBeneficiaries
      :isOpen="beneficiariesModalOpen"
      @close="beneficiariesModalOpen = false"
      :key="`specify-beneficiaries-modal`"
      :account="modalAccount"
      @save="$set(modalAccount, 'beneficiaries_info', $event)"
      @addNewClient="openNewClientModal()"
    />
    <specify-nominee
      :isOpen="nomineeModalOpen"
      @close="nomineeModalOpen = false"
      :key="`specify-nominee-modal`"
      :account="modalAccount"
      @save="$set(modalAccount, 'nominee', $event)"
      @addNewClient="openNewClientModal()"
    />
    <add-new-client
      :isOpen="newClientModalOpen && !hasFeatureFlag('client_data_hierarchy')"
      @close="
        newClientModalOpen = false;
        relationshipModalOpen = true;
      "
      :prePopulatedValues="true"
    />
    <AddNewClientNoCall
      :isOpen="newClientModalOpen && hasFeatureFlag('client_data_hierarchy')"
      @close="
        newClientModalOpen = false;
        relationshipModalOpen = true;
      "
      :prePopulatedValues="true"
    />
    <MessageDialog
      @ok="CDHIneligibleDialog = false"
      :isOpen="CDHIneligibleDialog"
      :width="'40%'"
    >
      <template v-slot:header>Cannot add clients</template>
      {{ cantAddClientsMsg }}
      <template v-slot:btn>Okay</template>
    </MessageDialog>
    <add-edit-relationship
      :isOpen="relationshipModalOpen"
      :action="'add'"
      @close="relationshipModalOpen = false"
    />
    <confirm-client-age
      :isOpen="ageModalOpen"
      @close="ageModalOpen = false"
      :person="client.person"
    />
  </v-dialog>
</template>

<script>
/* eslint-disable vue/max-len */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved   */
import _ from 'lodash';
import api from 'Services/api';
import apiV2 from 'Services/apiV2';
import moment from 'moment';
import v from 'Services/validation';
import {
  RELATIONSMAP,
  ACCOUNTTYPE,
  JURISDICTIONS,
  JURISDICTIONS_FCC,
  PAY_TO_TYPE,
  WORKFLOW,
  MARITALSTATUS,
  ACCOUNT_ELIGIBILITY_AGE,
  ACCOUNT_STATUS,
  ICP_MANDATENAMES,
  REGISTERED_ACCOUNT_TYPES,
  WARNING_MESSAGES,
  ACCOUNT_SUB_TYPE,
  FCC_GENDERS,
  BENEFICIARY_ROLES,
} from 'Services/constants';
import { getAge } from 'Services/helpers/time';
import { mapGetters } from 'vuex';
import {
  launchOnboarding,
  getSurveyEngineURL,
} from 'Services/helpers/onboarding';
import {
  createOnboardingWorkflow,
  createOnboardingIPWorkflow,
  createAccountOpeningWorkflow,
  fetchWorkflowAccounts,
  fetchWorkflows,
  sendOnboardingIPWorkflowSuccess,
  fetchNotCancelledOnboardingForClient,
} from 'Services/api/Workflows';
import { generateIPLink, generateIPLoginEmail } from 'Services/api/InvestorPortal';
import SetupRifPayment from 'Components/Vue-Routes/MyClients/ClientView/ClientMenu/SetupRifPayment';
import { getSessions } from 'Services/api/ClientConfirmationTool';
import EmailSvg from 'Assets/icons/email-01.svg';
import InPersonSvg from 'Assets/icons/meeting-in-person.svg';
import ScreenshareSvg from 'Assets/icons/screenshare-01.svg';
import MessageDialog from 'Components/Shared/MessageDialog';
import { v4 as uuidv4 } from 'uuid';
import AddNewClient from 'Components/Modals/AddNewClient';
import AddNewClientNoCall from 'Components/Modals/AddNewClientNoCall';
import AddEditRelationship from 'Components/Shared/AddEditRelationship';
import { isFirm } from 'Services/helpers/auth';
import AddToFeeGroup from 'Components/Shared/FeeManagement/AddToFeeGroup';
import getBaseType from 'Services/helpers/getAccountBaseType';
import { joinFeeGroup, createFeeGroup } from 'Services/api/FeeManagement';
import ConfirmClientAge from './AddInvestmentAccounts/ConfirmClientAge';
import SpecifyNominee from './AddInvestmentAccounts/SpecifyNominee';
import TriggerOnboarding from './AddInvestmentAccounts/TriggerOnboarding';
import TriggerOnboardingIP from './AddInvestmentAccounts/TriggerOnboardingIP';
import SpecifyBeneficiaries from './AddInvestmentAccounts/SpecifyBeneficiaries';
import SaveOptions from './AddInvestmentAccounts/SaveOptions';

export default {
  name: 'AddInvestmentAccounts',
  components: {
    SetupRifPayment,
    SpecifyBeneficiaries,
    AddNewClient,
    AddNewClientNoCall,
    ConfirmClientAge,
    SpecifyNominee,
    TriggerOnboarding,
    AddEditRelationship,
    AddToFeeGroup,
    MessageDialog,
    TriggerOnboardingIP,
    SaveOptions,
  },
  data() {
    return {
      currentScreen: 'addAccounts',
      workflowIsCreating: false,
      beneficiariesModalOpen: false,
      relationshipModalOpen: false,
      newClientModalOpen: false,
      CDHIneligibleDialog: false,
      cantAddClientsMsg: WARNING_MESSAGES.cannot_add_clients,
      nomineeModalOpen: false,
      ageModalOpen: false,
      rifModalOpen: false,
      env: '',
      surveyOptionsLoaded: false,
      tempAccountsEdit: [],
      v,
      baseAccountTypes: [
        'CASH',
        'CASH ITF',
        'RRSP',
        'TFSA',
        'RESP',
        'RRIF',
        'LIF',
        'LRIF',
        'PRIF',
        'LRSP',
        'LIRA',
        'JOINT CASH',
        'JOINT CASH ITF',
        'SPOUSAL RRSP',
        'SPOUSAL RRIF',
      ],
      accountSubtypes: {
        RRSP: ['R', false],
        'SPOUSAL RRSP': ['R', true],
        RRIF: ['N', false],
        'SPOUSAL RRIF': ['N', true],
        LRIF: ['X', false],
        TFSA: ['W', false],
        LRSP: ['L', false],
        LIF: ['X', false],
      },
      adjBaseAccountTypes: [],
      visibleAccountTypes: [],
      addingAccount: false,
      chooseFromStartIndex: 0,
      paginationLength: 7,
      page: 1,
      EmailSvg,
      InPersonSvg,
      ScreenshareSvg,
      jointDropdown: '',
      selectedRelationship: '',
      selectedBeneficiary: '',
      selectedJurisdiction: '',
      selectedIcpMandate: '',
      spousalInfo: {},
      onboardingSetupLoading: false,
      continueError: '',
      idleTimerBegin: null,
      getAge,
      RESPDialog: false,
      RSPDialog: null,
      RSPSpousalInfoDialog: null,
      modalAccount: {},
      validateBeneficiaryText: false,
      beneficiaries: [],
      isFirm,
      ICP_MANDATENAMES,
      ACCOUNT_SUB_TYPE,
      FCC_GENDERS,
      isEditingSubscriber: false,
      subscriber: {},
      workflows: [],
      workflowsLoaded: false,
      savingAccounts: false,
      beneficiaryError: false,
      beneficiaryErrorText: [],
    };
  },
  async created() {
    await this.$store.dispatch('fetchAllAccountUsages');
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setVisibleAccountTypes);
  },
  computed: {
    ...mapGetters([
      'hasFeatureFlag',
      'currentUser',
      'advisorTeams',
      'isCCTFeature',
      'custodian',
      'allAccountUsages',
    ]),
    firm() {
      return this.$store.getters.firm;
    },
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
    breakpoint() {
      return this.$vuetify.breakpoint.name;
    },
    jurisdictions() {
      return JURISDICTIONS;
    },
    client() {
      return this.$store.state.clients.activeClient;
    },
    adjustedBaseAccountTypes() {
      return this.baseAccountTypes.filter((a) => this.accountEligibility(a));
    },
    accountUses() {
      return this.allAccountUsages
        .map((usage) => ({ value: usage.id, text: usage.description }));
    },
    clientIsNotActive() {
      return (
        this.$store.state.clients.activeClient.client.client_status !== 'active'
      );
    },
    clientStatus() {
      return this.client.client.client_status;
    },
    IA() {
      return this.client.investment_accounts;
    },
    repCode() {
      return this.client.client.rep_code;
    },
    tempAccounts() {
      if (!this.client.investment_accounts.length) return [];
      const ias = this.client.investment_accounts.filter(
        (a) => a.account_status === 'T' || a.account_status === 'P',
      );
      return ias;
    },
    relationships() {
      return this.client.relationships.map((r) => {
        const displayName = r.client_details[0]
          ? r.client_details[0].display_name
          : '';
        const client2Id = r.client_details[0] ? r.client_details[0].id : '';
        return { text: displayName || '', value: client2Id, beneficiaryCheck: false };
      });
    },
    spousalRelationships() {
      return this.client.relationships
        .filter(
          (r) => r.relationship_type === 'SP' || r.relationship_type === 'CL' || r.relationship_type === 'ES',
        )
        .map((r) => {
          const displayName = r.client_details[0]
            ? r.client_details[0].display_name
            : '';
          const client2Id = r.client_details[0] ? r.client_details[0].id : '';
          return { text: displayName || '', value: client2Id };
        });
    },
    spousalType() {
      return this.client.relationships
        .filter(
          (r) => r.relationship_type === 'SP' || r.relationship_type === 'CL',
        )
        .map((r) => r.relationship_type)[0];
    },
    currentOnboardingWorkflow() {
      return this.client.workflows.find((w) => w.type === 'client_onboarding');
    },
    missingClientInfo() {
      const {
        email,
        firm_id: firmId,
        mobile_number: mobileNumber,
        home_number: homeNumber,
      } = this.client.client;

      const phoneNumber = this.hasFeatureFlag('investor_portal') ? (mobileNumber || homeNumber) : mobileNumber;

      return !(email && phoneNumber);
    },
    isCreating() {
      return !!this.workflowIsCreating;
    },
    accountStatus() {
      return _.invert(ACCOUNT_STATUS);
    },
    // zdes
    age() {
      return this.client.person.date_of_birth
        ? getAge(this.client.person.date_of_birth)
        : null;
    },
    hasSpouse() {
      const isMarried = this.client.person.marital_status
        === MARITALSTATUS.married.toLowerCase();
      const hasSpousalRelationship = this.client.relationships.some((r) => [
        RELATIONSMAP.CL,
        RELATIONSMAP.SP,
        RELATIONSMAP.ES,
      ].includes(
        r.relationship_type,
      ));
      return isMarried || hasSpousalRelationship;
    },
    feeGroupsEnabled() {
      return (
        this.$store.state.app.features.fee_groups
        && this.client.client.client_type === 'person'
        && (this.clientStatus === 'active' || this.clientStatus === 'prospect')
      );
    },
    retriggerOnboardingEnabled() {
      const ipOnboardingWorkflows = this.workflows.filter((wf) => [WORKFLOW.TYPES.client_onboarding_fcc_ip].includes(wf.workflowType));
      if (ipOnboardingWorkflows.some((wf) => ['completed', 'in_progress'].includes(wf.workflowStatus))) {
        return false;
      }
      return (
        ipOnboardingWorkflows.some((wf) => (
          wf.workflowStatus === 'Cancelled'
          && !wf.steps.find((s) => s.stepName === 'datacapture')
        ))
      );
    },
    useColumnEnabled() {
      return this.hasFeatureFlag('use_of_account');
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.fetchWorkflows();
        this.tempAccountsEdit = _.cloneDeep(this.tempAccounts);
        if (!this.tempAccountsEdit.length) {
          this.addAccount('TFSA');
          this.addAccount('CASH');
        }
        window.addEventListener('resize', this.setVisibleAccountTypes);
      }
    },
    tempAccounts: {
      deep: true,
      handler() {
        this.tempAccountsEdit = _.cloneDeep(this.tempAccounts);
        this.validateAccounts();
      },
    },
    tempAccountsEdit: {
      deep: true,
      handler() {
        this.validateAccounts();
      },
    },
    age: {
      handler() {
        this.validateAccounts();
      },
    },
    page() {
      this.setVisibleAccountTypes();
    },
  },
  methods: {
    async fetchWorkflows() {
      this.workflows = [];
      this.workflowsLoaded = false;
      try {
        const { workflows } = await fetchWorkflows(
          0,
          null,
          null,
          null,
          null,
          null,
          this.client.client.id,
          null,
          null,
        );
        this.workflows = [...this.workflows, ...workflows];
        this.currentOffset += workflows.length;
      } catch (e) {
        this.$store.dispatch('setSnackbar', {
          text: 'Error fetching workflows',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
      this.workflowsLoaded = true;
    },
    jurisdictionsFcc(acctType) {
      return JURISDICTIONS_FCC[acctType];
    },
    baseAccType(account) {
      return getBaseType(account);
    },
    setRespType(account, type) {
      this.$set(account, 'resp_type', type);
      this.$set(account, 'beneficiaries_info', { beneficiaries: [] });
    },
    setRespICP(account) {
      this.$set(account, 'icp_indicator', !account.icp_indicator);
    },
    setAccountUse(account, $event) {
      this.$set(account, 'use_of_account', $event);
    },
    addTrustee(option) {
      this.tempAccountsEdit = this.tempAccountsEdit.map((a) => {
        if (a.tempID && this.RESPDialog.tempID === a.tempID) {
          a.trustee = option;
        }

        return a;
      });
      this.RESPDialog = null;
      if (
        this.custodian === 'fcc'
        && !this.hasValidGender(this.client.person.sex)
      ) {
        this.editRESPSubscriber(this.client.client);
      }
    },
    accountEditable(account) {
      if (!account.id) return true;
      if (account.id && account.account_status === 'P') return false;
      return account.primary_client_id === this.client.client.id;
    },
    hasNonEstatePrimaryBeneficiaries(account) {
      return account.beneficiaries.some(
        (b) => !b.is_estate && b.priority === 'P',
      );
    },
    hasPrimaryBeneficiariesWithRole(account, role) {
      return account.beneficiaries.some(
        (b) => b.role === role && b.priority === 'P',
      );
    },
    hasContingentBeneficiariesWithRole(account, role) {
      return account.beneficiaries.some(
        (b) => b.role === role && b.priority === 'C',
      );
    },
    hasPrimaryBeneficiaries(account) {
      return account.beneficiaries.some(
        (b) => b.priority === 'P',
      );
    },
    hasAccountType(account, type) {
      if (account.id) {
        return ACCOUNTTYPE[account.account_type_short] === type;
      }
      // When comparing with existing accounts, we must convert the base account type
      // to the account type short stored in the database.
      // Most of the account type matches are 1:1, but there are a few exceptions.
      // The getNicknameFromBaseType function handles these exceptions.
      return this.getNicknameFromBaseType(account.base_type_string) === type;
    },
    isRegisteredAccount(account) {
      if (account.id) {
        return REGISTERED_ACCOUNT_TYPES.includes(
          ACCOUNTTYPE[account.account_type_short],
        );
      }
      return REGISTERED_ACCOUNT_TYPES.includes(account.base_type_string);
    },
    hasValidGender(gender) {
      return FCC_GENDERS[gender] !== undefined;
    },
    generateEstateCheck(account) {
      return !!account.beneficiaries.filter(
        (a) => a.is_estate && a.percentage === 100,
      ).length;
    },
    openNewClientModal() {
      if (
        this.hasFeatureFlag('client_data_hierarchy')
        && !this.advisorTeams.length
      ) {
        this.CDHIneligibleDialog = true;
        return;
      }
      this.newClientModalOpen = true;
    },
    firstRegisteredAccount() {
      const firstRegisteredAccount = [];
      this.tempAccountsEdit.forEach((a) => {
        if (
          a.tempID
          && this.isRegisteredAccount(a)
          && !firstRegisteredAccount.length
        ) {
          firstRegisteredAccount.push(a.tempID);
        }
      });
      return firstRegisteredAccount[0];
    },
    addBeneficiary(accountID, priority, role) {
      this.tempAccountsEdit = this.tempAccountsEdit.map((a) => {
        const id = a.id || a.tempID;

        if (accountID === id) {
          a.beneficiaries = a.beneficiaries || [];
          a.beneficiaries.push({
            tempID: uuidv4(),
            is_estate: false,
            client: '',
            priority,
            account: accountID,
            percentage: '0',
            role,
          });
        }
        return a;
      });
    },
    removeContingentBeneficiaries(index) {
      // remove beneficiaries with priority C
      const newBeneficiaries = this.tempAccountsEdit[index].beneficiaries.filter(
        (b) => b.priority !== 'C',
      );
      this.tempAccountsEdit[index].beneficiaries = newBeneficiaries;
    },
    removeBeneficiary(account, index, id) {
      const beneficiaries = this.tempAccountsEdit[index].beneficiaries.filter(
        (b) => {
          const beneficiaryID = b.id ? b.id : b.tempID;
          return beneficiaryID !== id;
        },
      );
      this.tempAccountsEdit[index].beneficiaries = beneficiaries;
      // if all primary beneficiaries are removed, remove the contingent beneficiaries
      if (!beneficiaries.some((b) => b.priority === 'P' && !b.is_estate)) {
        if (beneficiaries.some((b) => b.priority === 'C')) {
          this.removeContingentBeneficiaries(index);
        }
      }
      this.updateEstatePercentage(account);
    },
    setRSPSpousalInfoDialog(index) {
      if (this.custodian === 'fcc' && this.RSPDialog.type === 'S') {
        this.RSPSpousalInfoDialog = {
          index,
        };
        api
          .get(`/datahub/persons/${this.spousalRelationships[0].value}/`)
          .then((res) => {
            this.spousalInfo = {
              id: res.id,
              birthDate: res.date_of_birth,
              SIN: res.SIN,
            };
          })
          .catch((err) => console.log('err', err));
      }
    },
    updateSpousalInfo() {
      if (!this.$refs.spousalInfoForm.validate()) return;
      api
        .post(`/datahub/persons/${this.spousalInfo.id}/`, {
          SIN: this.spousalInfo.SIN,
          date_of_birth: this.spousalInfo.birthDate,
        })
        .then((res) => {
          if (res.id) {
            this.RSPDialog = null;
            this.RSPSpousalInfoDialog = null;
          }
        })
        .catch(() => {
          this.$store.dispatch('setSnackbar', {
            text: 'Error updating spousal information',
            type: 'error',
            topic: '',
          });
          this.$store.dispatch('flipSnackbarSwitch');
          // remove RRSP-S account and clear diaplog
          this.removeAccount(
            this.RSPSpousalInfoDialog && this.RSPSpousalInfoDialog.index,
          );
          this.RSPSpousalInfoDialog = null;
          this.RSPDialog = null;
        });
    },
    contingentBeneficiaryCheck(account) {
      if (!account.beneficiaries) return false;
      const check = account.beneficiaries.filter(
        (b) => b.priority === 'C',
      ).length;
      return check;
    },
    hasCADAccountWithType(type) {
      // When comparing with existing accounts, we must convert the base account type
      // to the account type short stored in the database.
      // Most of the account type matches are 1:1, but there are a few exceptions.
      // The getNicknameFromBaseType function handles these exceptions.
      const existingAccountType = this.getNicknameFromBaseType(type);

      const hasExistingCADAccountWithType = this.IA.some(
        (a) => a.currency === 'CAD'
          && ACCOUNTTYPE[a.account_type_short] === existingAccountType,
      );
      const hasTempCADAccountWithType = this.tempAccountsEdit.some(
        (a) => !a.id && a.currency === 'CAD' && a.base_type_string === type,
      );
      return hasExistingCADAccountWithType || hasTempCADAccountWithType;
    },
    isUSDPermittedFCC(accountType) {
      if (accountType === 'RESP') return false;
      if (
        ['CASH', 'JOINT CASH', 'CASH ITF', 'JOINT CASH ITF'].includes(
          accountType,
        )
      ) return true;
      return this.hasCADAccountWithType(accountType);
    },
    currencyValidation(accountType, number = 0) {
      if (['RESP', 'LIRA', 'PRIF'].includes(accountType)) return true; // can only be CAD
      if (
        ['CASH', 'JOINT CASH', 'CASH ITF', 'JOINT CASH ITF'].includes(
          accountType,
        )
      ) {
        return false;
      } // can be both
      const IAcheck = this.IA.filter(
        (a) => a.subtype === this.accountSubtypes[accountType][0]
          && a.currency === 'CAD'
          && a.spousal_indicator === this.accountSubtypes[accountType][1],
      ).length;
      let TAcheck = this.tempAccountsEdit
        .filter((a) => !a.id)
        .filter(
          (a) => a.base_type_string === accountType && a.currency === 'CAD',
        ).length;
      TAcheck -= number;
      return !(IAcheck || TAcheck);
    },
    closeModal() {
      if (this.addingAccount) {
        this.addingAccount = !this.addingAccount;
      }
      this.$store.dispatch('getInvestmentAccounts', this.client.client.id);
      this.$store.commit('CLOSE_CURRENT_MODAL');
    },
    accountEligibility(accountType) {
      if (!this.age && this.age !== 0) {
        if (
          ['JOINT CASH ITF', 'JOINT CASH'].includes(accountType)
          && !this.client.relationships.length
        ) {
          return false;
        }
        if (
          ['SPOUSAL RRSP', 'SPOUSAL RRIF'].includes(accountType)
          && !this.hasSpouse
        ) {
          return false;
        }
        return true;
      }
      if (this.age < 18) return false;
      if (
        ['JOINT CASH ITF', 'JOINT CASH'].includes(accountType)
        && !this.client.relationships.length
      ) {
        return false;
      }
      if (
        ['SPOUSAL RRSP', 'SPOUSAL RRIF'].includes(accountType)
        && !this.hasSpouse
      ) {
        return false;
      }
      if (
        ['RRSP', 'LRSP'].includes(accountType)
        && !(this.age < ACCOUNT_ELIGIBILITY_AGE.RRSP_LRSP)
      ) {
        return false;
      }
      if (
        ['LIF', 'LRIF', 'RRIF'].includes(accountType)
        && !(this.age >= ACCOUNT_ELIGIBILITY_AGE.LIF_LRIF_RRIF)
      ) {
        return false;
      }
      if (
        accountType === 'SPOUSAL RRSP'
        && !(this.age < ACCOUNT_ELIGIBILITY_AGE.RRSP_LRSP)
      ) {
        return false;
      }
      if (
        accountType === 'SPOUSAL RRIF'
        && !(this.age >= ACCOUNT_ELIGIBILITY_AGE.LIF_LRIF_RRIF)
      ) {
        return false;
      }
      return true;
    },
    eligibilityMessage(accountType) {
      if (!this.age && this.age !== 0) {
        if (
          ['JOINT CASH ITF', 'JOINT CASH'].includes(accountType)
          && !this.client.relationships.length
        ) {
          return 'Cannot open a joint account if the client has no relationships';
        }
        if (
          ['SPOUSAL RRSP', 'SPOUSAL RRIF'].includes(accountType)
          && !this.hasSpouse
        ) {
          return 'Cannot open a spousal account if client does not have a spousal relationship';
        }
        return '';
      }
      if (this.age < 18) return 'Cannot open an account for clients under 18';
      if (
        ['JOINT CASH ITF', 'JOINT CASH'].includes(accountType)
        && !this.client.relationships.length
      ) {
        return 'Cannot open a joint account if the client has no relationships';
      }
      if (
        ['SPOUSAL RRSP', 'SPOUSAL RRIF'].includes(accountType)
        && !this.hasSpouse
      ) {
        return 'Cannot open a spousal account if client does not have a spousal relationship';
      }
      if (
        ['RRSP', 'LRSP', 'SPOUSAL RRSP'].includes(accountType)
        && !(this.age < ACCOUNT_ELIGIBILITY_AGE.RRSP_LRSP)
      ) {
        return `Can only open this account type for clients younger than ${ACCOUNT_ELIGIBILITY_AGE.RRSP_LRSP}`;
      }
      if (
        ['LIF', 'LRIF', 'RRIF', 'SPOUSAL RRIF'].includes(accountType)
        && !(this.age >= ACCOUNT_ELIGIBILITY_AGE.LIF_LRIF_RRIF)
      ) {
        return `Cannot open this account type for clients under ${ACCOUNT_ELIGIBILITY_AGE.LIF_LRIF_RRIF}`;
      }
      return '';
    },
    resetIdleTimer() {
      this.idleTimerBegin = new Date().getTime();
      setTimeout(() => {
        if (new Date().getTime() - this.idleTimerBegin >= 1000 * 10000) {
          if (this.addingAccount) this.toggleAddAccount();
        }
      }, 1000 * 10);
    },
    loadSaveOptionsScreen() {
      this.currentScreen = 'chooseSaveOption';
    },
    beforeCreateNewAccount() {
      if (!this.validateAccounts()) return;
      this.workflowIsCreating = true;
      const registeredAccountsCheck = this.tempAccountsEdit.filter(
        (a) => this.isRegisteredAccount(a) && a.tempID,
      );
      if (registeredAccountsCheck.length) {
        this.currentScreen = 'addBeneficiary';
      } else {
        this.loadSaveOptionsScreen();
      }
    },
    async createNewAccount() {
      let validate = '';
      this.tempAccountsEdit.forEach((a) => {
        validate += this.validateBeneficiary(a, 'P');
        validate += this.validateBeneficiary(a, 'C');
      });
      if (validate) {
        this.workflowIsCreating = false;
        this.validateBeneficiaryText = true;
      }

      let accounts;
      try {
        accounts = await this.updateAccounts();
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          text: 'Error updating accounts',
          type: 'error',
          topic: '',
        });
        this.$store.dispatch('flipSnackbarSwitch');
        this.onboardingSetupLoading = false;
        this.workflowIsCreating = false;
      }

      try {
        if (this.hasFeatureFlag('new_workflows')) {
          const newAccounts = accounts.filter((x) => x.isNew || x.account_status === 'T');
          const jointClientIds = newAccounts.reduce(
            (acc, r) => ((r.joint_client_id || []).length
              ? [...acc, ...r.joint_client_id]
              : acc),
            [],
          );
          const clientIds = [this.client.client.id, ...jointClientIds];
          const newAccountIds = newAccounts.map((x) => x.id);

          // Filter all Account Opening Workflows
          const accountOpeningWfs = this.workflows.filter(
            (w) => w.workflowType.indexOf('account_opening_fcc') !== -1
              && w.workflowStatus.toLowerCase() !== 'cancelled'
              && w.workflowStatus.toLowerCase() !== 'canceled',
          );

          // Fetch all accounts for each workflow
          const allWfAccounts = await Promise.all(
            accountOpeningWfs.map(async (accountOpeningWf) => {
              const wfAccounts = await fetchWorkflowAccounts(
                accountOpeningWf.id,
              );
              return wfAccounts.results;
            }),
          );

          for (let i = 0; i < allWfAccounts.length; i++) {
            const singleWfAccounts = allWfAccounts[i];

            for (let j = 0; j < singleWfAccounts.length; j++) {
              const a = singleWfAccounts[j];
              if (newAccountIds.includes(a.account)) {
                throw new Error('Account already in workflow');
              }
            }
          }

          await createAccountOpeningWorkflow(clientIds, newAccountIds);
          this.$store.dispatch('flipSnackbarSwitch');
          this.$store.dispatch('setSnackbar', {
            text: 'Account opening workflow was successfully created',
            type: 'success',
            topic: '',
          });
        } else {
          await this.createOldAccountOpeningWorkflow(accounts);
        }
      } catch (error) {
        this.$store.dispatch('setSnackbar', {
          text: 'Error triggering workflow',
          type: 'error',
          topic: '',
        });
        this.$store.dispatch('flipSnackbarSwitch');
        this.onboardingSetupLoading = false;
        this.surveyOptionsLoaded = false;
        this.workflowIsCreating = false;
      }

      this.onboardingSetupLoading = false;
      this.surveyOptionsLoaded = false;
      this.$store.dispatch('getWorkflows', this.client.client.id);
      this.$store.dispatch('getInvestmentAccounts', this.client.client.id);
      this.workflowIsCreating = false;
      this.$store.commit('CLOSE_CURRENT_MODAL');
    },
    async createOldAccountOpeningWorkflow(accounts) {
      // TODO: DELETE THIS ONCE V2 OF WORKFLOWS IS RELEASED
      const newAccounts = accounts.filter((x) => x.isNew);
      if (newAccounts.length) {
        let clients = [
          {
            role: 'Primary client',
            pas_client_id: this.client.client.id,
            client_data: this.client.client,
          },
        ];
        const newClients = newAccounts.reduce(
          (acc, r) => (
            r.joint_client_id.length
              ? [...acc, ...r.joint_client_id]
              : acc
          ),
          [],
        );
        let clientsToAdd = [];
        if (newClients.length) {
          clientsToAdd = await Promise.all(
            newClients.map(async (clientId) => {
              const clientData = await api.get(
                `/datahub/clients/${clientId}/`,
              );
              return {
                role: 'Secondary Client',
                pas_client_id: clientId,
                client_data: clientData,
              };
            }),
          );
        }
        clients = [...clients, ...clientsToAdd];
        const workflowData = {
          accounts: newAccounts,
          clients,
        };
        await api.post('/advisor_portal/workflow/new', {
          pas_client_ids: [this.client.client.id],
          workflow_type: 'account_opening',
          firm: this.firm,
          workflowData,
        });
        this.$store.dispatch('flipSnackbarSwitch');
        this.$store.dispatch('setSnackbar', {
          text: 'Account opening workflow was successfully created',
          type: 'success',
          topic: '',
        });
      }
    },
    getJointPlaceholderText(account) {
      if (
        !account.primary_client_id
        || account.primary_client_id === this.client.client.id
      ) {
        if (account.joint_client_id && account.joint_client_id.length) {
          const jointClient = this.relationships.find(
            (r) => r.value === account.joint_client_id[0],
          );
          return jointClient
            ? jointClient.text
            : 'Relation deleted';
        }

        return undefined;
      }
      // is joint
      const jointAccount = this.relationships.find(
        (r) => r.value === account.primary_client_id,
      );

      return jointAccount ? jointAccount.text : 'Relation deleted';
    },
    getBeneficiaryLabelText(b) {
      if (!b.client) {
        return `+ Select ${BENEFICIARY_ROLES[b.role]}`;
      }
      return this.relationships.find((r) => r.value === b.client)
        ? this.relationships.find((r) => r.value === b.client).text
        : 'Relation deleted';
    },
    toggleBeneficiaryDropdown(b) {
      if (this.jointDropdown) this.jointDropdown = '';
      else {
        this.jointDropdown = b;
        this.selectedRelationship = b.client;
      }
    },
    setJointSubscriber(account, spousalClientId) {
      let jointClientID = [];

      if (spousalClientId) {
        this.$set(account, 'joint_indicator', true);

        const jointSubscriber = this.client.relationships.find(
          (r) => r.client_details[0].id === spousalClientId,
        ).client_details[1].person_details[0];

        if (
          this.custodian === 'fcc'
          && !this.hasValidGender(jointSubscriber.sex)
        ) {
          this.editRESPSubscriber(jointSubscriber);
        }
        jointClientID = [jointSubscriber.id];
      } else {
        this.$set(account, 'joint_indicator', false);
        this.$set(account, 'joint_type', 0);
      }

      this.$set(account, 'joint_client_id', jointClientID);
    },
    saveSubscriber(subscriber) {
      const data = {
        sex: subscriber.gender.toLowerCase(),
      };
      this.$store
        .dispatch('updatePersonClient', {
          id: subscriber.id,
          data,
          refetch: false,
        })
        .then((res) => {
          if (res.id) this.isEditingSubscriber = false;
        });
    },
    getJointSubscriberText(subscriberId) {
      const subscriber = this.spousalRelationships
        .find((r) => r.value === subscriberId);

      return subscriber ? subscriber.text : '';
    },
    relationshipSelected(account, relationshipID) {
      this.$set(account, 'joint_indicator', true);
      this.$set(account, 'joint_client_id', [relationshipID]);
      this.selectedRelationship = ''; // clear form
    },
    beneficiarySelected(b, relationshipID) {
      this.$set(b, 'client', relationshipID);
      this.selectedRelationship = ''; // clear form
      this.jointDropdown = ''; // close dropdown block
    },
    updateBeneficiaryPercentage(account, beneficiary, value) {
      const val = Number(value[0]) === 0 ? value.slice(1, value.length) : value;
      this.$set(beneficiary, 'percentage', val);
      this.updateEstatePercentage(account);
    },
    updateEstatePercentage(account) {
      this.tempAccountsEdit = this.tempAccountsEdit.map((a) => {
        if (a.tempID && account.tempID === a.tempID) {
          let notEstate = 0;
          let notEstateC = 0;
          a.beneficiaries.forEach((b) => {
            if (!b.is_estate && b.priority === 'P') {
              notEstate += Number(b.percentage);
            }
            if (!b.is_estate && b.priority === 'C') {
              notEstateC += Number(b.percentage);
            }
          });
          a.beneficiaries = a.beneficiaries.map((b) => {
            if (b.is_estate && b.priority === 'P') {
              b.percentage = 100 - notEstate;
              b.percentage = b.percentage.toString();
            }
            if (b.is_estate && b.priority === 'C') {
              b.percentage = 100 - notEstateC;
              b.percentage = b.percentage.toString();
            }
            return b;
          });
        }
        return a;
      });
    },
    jurisdictionSelected(account, jurisdiction) {
      this.$set(account, 'jurisdiction', jurisdiction);
      this.selectedJurisdiction = ''; // clear form
    },
    icpMandateSelected(account, icpMandate) {
      this.$set(
        account,
        'mandate_name',
        _.findKey(ICP_MANDATENAMES, (e) => e === icpMandate),
      );
      this.selectedIcpMandate = '';
    },
    validateAccounts() {
      if (!this.tempAccountsEdit.length) {
        this.continueError = 'Please add an account to proceed';
        return false;
      }
      const isValid = this.tempAccountsEdit
        .filter((a) => !a.id) // don't validate accounts that have beed added in prior sessions
        .every((account) => {
          if (this.baseAccType(account).includes('JOINT')) {
            if (
              !account.joint_client_id
              || !account.joint_client_id.length
              || !account.joint_client_id[0]
            ) {
              this.continueError = 'Please select a secondary relationship for all joint accounts';
              return false;
            }
          }
          if (
            ['RRIF', 'LIF', 'LRIF', 'PRIF', 'SPOUSAL RRIF'].includes(
              this.baseAccType(account),
            )
          ) {
            if (account.rif_details === undefined) {
              this.continueError = 'Please add the necessary Payment Details';
              return false;
            }
          }
          if (
            ['CASH ITF', 'JOINT CASH ITF'].includes(this.baseAccType(account))
          ) {
            if (!account.nominee) {
              this.continueError = 'Please select a nominee for all ITF accounts';
              return false;
            }

            const hasItfWithDifferentNominee = this.tempAccountsEdit.some(
              (acc) => !acc.id
                && ['CASH ITF', 'JOINT CASH ITF'].includes(this.baseAccType(acc))
                && acc.nominee
                && acc.nominee !== account.nominee,
            );

            // if (hasItfWithDifferentNominee) {
            //   this.continueError = 'Cannot open ITF accounts with different nominees in the same workflow. Please start a new workflow for each nominee';
            //   return false;
            // }
          }
          if ([
            'RRIF',
            'LIF',
            'LRIF',
            'PRIF',
            'SPOUSAL RRIF',
          ].includes(this.baseAccType(account))) {
            if (!this.age) {
              this.continueError = 'Please confirm client age';
              return false;
            }
          }
          // LOCKED IN needs jurisdiction selected
          if (
            ['LIF', 'LRIF', 'PRIF', 'LRSP', 'LIRA'].includes(
              this.baseAccType(account),
            )
          ) {
            if (!account.jurisdiction || account.jurisdiction === 'NA') {
              this.continueError = 'Please select a jurisdiction for all locked in accounts';
              return false;
            }
          }
          // RESP NEEDS BENEFICIARY SELECTED
          if (this.baseAccType(account) === 'RESP') {
            if (
              !account.beneficiaries_info
              || !account.beneficiaries_info.beneficiaries.length
            ) {
              this.continueError = 'Please select a beneficiary for all RESP accounts';
              return false;
            }
          }
          // ICP NEEDS TO HAVE MANDATE CODE SELECTED
          if (account.icp_indicator && !account.mandate_name) {
            this.continueError = 'Please select a mandate code for all ICP accounts';
            return false;
          }
          if (this.useColumnEnabled) {
            if (account.use_of_account === undefined) {
              this.continueError = 'Please add a Use for all accounts';
              return false;
            }
          }

          return true;
        });

      if (!isValid) return false;

      const currentUSDAccountsToCheck = this.tempAccountsEdit
        .filter((a) => !a.id)
        .filter((a) => a.currency === 'USD')
        .map((a) => a.base_type_string)
        .filter((a) => [
          'RRSP',
          'TFSA',
          'RRIF',
          'LIF',
          'LRIF',
          'LRSP',
          'SPOUSAL RRSP',
          'SPOUSAL RRIF',
        ].includes(a));
      const USDAccountValidation = [];
      if (currentUSDAccountsToCheck.length) {
        currentUSDAccountsToCheck.forEach((a) => {
          const IAcheck = this.IA.filter(
            (ia) => this.hasCADAccountWithType(a)
              && ia.spousal_indicator === this.accountSubtypes[a][1],
          );
          const TAcheck = this.tempAccountsEdit.filter(
            (ta) => !ta.id && ta.base_type_string === a && ta.currency === 'CAD',
          );
          if (!(IAcheck.length || TAcheck.length)) {
            USDAccountValidation.push(a);
          }
        });
      }
      if (USDAccountValidation.length) {
        this.continueError = `Cannot open a USD ${USDAccountValidation[0]} account `;
        this.continueError += `before opening a CAD ${USDAccountValidation[0]} account`;
        return false;
      }

      this.continueError = '';
      return true;
    },
    async createGroup() {
      try {
        const feeGroup = await createFeeGroup({
          name: `${this.client.client.display_name}'s Fee Group`,
          description: `${this.client.client.display_name}'s Fee Group`,
        });
        if (!feeGroup.id) throw Error;
        const clientFeeGroupRes = await joinFeeGroup({
          client: this.client.client.id,
          fee_group: feeGroup.id,
        });
        if (!clientFeeGroupRes.id) throw Error;
        await this.$store.dispatch('setFeeGroup', this.client.client.id);
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          text: 'Something went wrong, please refresh the page',
          type: 'fail',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
    async continueClick() {
      if (!this.validateAccounts()) return;
      const registeredAccountsCheck = this.tempAccountsEdit.filter(
        (a) => this.isRegisteredAccount(a) && a.tempID,
      );
      if (registeredAccountsCheck.length) this.currentScreen = 'addBeneficiary';
      else if (this.feeGroupsEnabled) {
        if (!this.client.feeGroup.id) await this.createGroup();
        this.currentScreen = 'addToFeeGroup';
      } else {
        this.currentScreen = 'chooseSurveyOption';
        const that = this;
        setTimeout(() => {
          that.surveyOptionsLoaded = true;
        }, 200);
      }
    },
    async continueClickNext() {
      let validate = '';
      this.tempAccountsEdit.forEach((a) => {
        validate += this.validateBeneficiary(a, 'P');
        validate += this.validateBeneficiary(a, 'C');
      });
      if (validate) {
        this.validateBeneficiaryText = true;
        return;
      }
      if (this.feeGroupsEnabled) {
        if (!this.client.feeGroup.id) await this.createGroup();
        this.currentScreen = 'addToFeeGroup';
      } else {
        const that = this;
        this.currentScreen = 'chooseSurveyOption';
        setTimeout(() => {
          that.surveyOptionsLoaded = true;
        }, 200);
      }
    },
    continueNext() {
      this.currentScreen = 'chooseSurveyOption';
      const that = this;
      setTimeout(() => {
        that.surveyOptionsLoaded = true;
      }, 200);
    },
    async validateTCP() {
      if (!this.hasFeatureFlag('trusted_contact_person') || this.hasFeatureFlag('investor_portal') || this.client.person.trusted_contact_person === 'N') {
        return true;
      }
      if (this.client.relationships.length > 0 && this.client.person.trusted_contact_person === 'Y') {
        const tcpClient = this.client.relationships.find((r) => r.trusted_contact_person === 'Y');
        if (tcpClient && tcpClient.client_details) {
          let isEligible = false;
          try {
            const { response } = await apiV2.get(`/clients/${tcpClient.client_details[0].id}/can_be_trusted_contact_person/`);
            isEligible = response;
          } catch (err) {
            console.log(err);
          }
          return isEligible;
        }
      }
      return false;
    },
    validateBeneficiary(account, type) {
      const errorText = [];
      if (account.beneficiaries && account.beneficiaries.length) {
        const beneficiariesByType = account.beneficiaries.filter(
          (b) => b.priority === type,
        );
        if (beneficiariesByType.length) {
          const clients = beneficiariesByType
            .map((b) => b.client)
            .filter((c) => c);
          const hasDuplicates = clients.some(
            (c, i) => clients.indexOf(c) !== i,
          );
          const percentage = beneficiariesByType
            .map((b) => Number(b.percentage))
            .reduce((a, b) => a + b, 0);
          const lessThanZero = beneficiariesByType.filter(
            (b) => Number(b.percentage) < 0,
          );

          if (percentage > 100) {
            errorText.push('Percentages cannot exceed 100.');
          }
          if (hasDuplicates) {
            errorText.push('Duplicated beneficiary.');
          }
          if (clients.length !== beneficiariesByType.length) {
            errorText.push('Please select the beneficiary from the list of relationships.');
          }
          if (lessThanZero.length !== 0) {
            errorText.push('Cannot have negative percentages.');
          }
        }
      }
      return errorText.join(' ');
    },
    async saveTempAccts() {
      try {
        this.savingAccounts = true;
        await this.updateAccounts();
        this.$store.dispatch('setSnackbar', {
          text: 'Accounts have been saved',
          type: 'success',
          topic: '',
        });
        this.$store.dispatch('getInvestmentAccounts', this.client.client.id);
        this.currentScreen = 'addAccounts';
        this.workflowIsCreating = false;
        this.$store.commit('CLOSE_CURRENT_MODAL');
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          text: 'Error updating accounts',
          type: 'error',
          topic: '',
        });
        this.$store.dispatch('flipSnackbarSwitch');
        this.onboardingSetupLoading = false;
      }
      this.savingAccounts = false;
    },
    async triggerOnboardingWorkflowIP({ retrigger, inPerson, skipSurvey }) {
      let accounts;
      try {
        this.savingAccounts = true;
        accounts = await this.updateAccounts();
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          text: 'Error updating accounts',
          type: 'error',
          topic: '',
        });
        this.$store.dispatch('flipSnackbarSwitch');
        this.onboardingSetupLoading = false;
        this.savingAccounts = false;
        return;
      }
      try {
        if (this.hasFeatureFlag('new_workflows') && this.custodian === 'fcc') {
          const jointClientIds = accounts.reduce(
            (acc, r) => ((r.joint_client_id || []).length
              ? [...acc, ...r.joint_client_id]
              : acc),
            [],
          );
          const clientIds = [this.client.client.id, ...jointClientIds];
          // if client does not have an active onboarding workflow, create one
          const activeOnboardingWorkflows = await fetchNotCancelledOnboardingForClient(clientIds[0]);
          // or, if client added a non-joint account and is currently in an joint account onboarding, re-create a regular onboarding workflow
          const hasNonJointAccount = accounts.some((a) => !a.joint_indicator);
          const workflowsIsJoint = activeOnboardingWorkflows.workflows.some((wf) => wf.workflowName.includes('Joint Account'));

          if (activeOnboardingWorkflows.totalCount === 0 || (hasNonJointAccount && workflowsIsJoint)) {
            await createOnboardingIPWorkflow(clientIds, inPerson);
            this.$store.dispatch('setSnackbar', {
              text: 'Creating onboarding workflow',
              type: 'success',
              topic: '',
            });
          }

          if (!retrigger && !inPerson) {
            if (this.client.IPInviteStatus === 2) {
              // send email that direct to IP login page
              await generateIPLoginEmail({ client_id: this.client.client.id });
            } else {
              // send invite email if not invite yet or not accepted
              const invitation = await generateIPLink({ client_id: this.client.client.id });
              if (invitation.message && invitation.message.toLowerCase() === 'success') { //
                await this.$store.dispatch('setClientIPInvitationStatus', this.client.client.id);
              }
            }
          }

          if (retrigger) {
            if (skipSurvey) {
              await api.post('/advisor_portal/set_client_datacapture_as_completed/', {
                client_id: this.client.client.id,
              });
              await this.$store.dispatch('getWorkflows', this.client.client.id);
              const newOnboardingWorkflow = this.client.workflows
                .find((wf) => wf.workflowType === WORKFLOW.TYPES.client_onboarding_fcc_ip);
              await sendOnboardingIPWorkflowSuccess(newOnboardingWorkflow.id);
            } else {
              await api.post('/advisor_portal/reset_client_datacapture_status/', {
                client_id: this.client.client.id,
                firm: this.firm,
              });
            }
          }

          this.onboardingSetupLoading = false;
          this.savingAccounts = false;
          this.surveyOptionsLoaded = false;
          // refresh client info
          this.$store.dispatch('getWorkflows', this.client.client.id);
          this.$store.dispatch('getInvestmentAccounts', this.client.client.id);

          if (retrigger) this.currentScreen = 'onboardingWorkflowCreated';
          if (!retrigger && !inPerson) this.currentScreen = 'surveyEmailSent';
          if (!retrigger && inPerson) {
            this.currentScreen = 'addAccounts';
            this.$store.commit('CLOSE_CURRENT_MODAL');
            this.$router.push({
              name: 'KycSurveyPage',
              params: { clienID: this.$store.state.clients.activeClient.client.id },
              query: { context: 'In Person Onboarding Survey' },
            });
          }
        }
      } catch (err) {
        console.log(err);
        this.$store.dispatch('setSnackbar', {
          text: 'Error creating workflow',
          type: 'error',
          topic: '',
        });
        this.$store.dispatch('flipSnackbarSwitch');
        this.onboardingSetupLoading = false;
        this.savingAccounts = false;
      }
    },
    async triggerOnboarding(inPerson = true) {
      let accounts;
      try {
        accounts = await this.updateAccounts();
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          text: 'Error updating accounts',
          type: 'error',
          topic: '',
        });
        this.$store.dispatch('flipSnackbarSwitch');
        this.onboardingSetupLoading = false;
        return;
      }
      // TCP info not asked in non-ip onboarding, need to have it before starting onboarding
      const validTCP = await this.validateTCP();
      if (!validTCP) {
        this.$store.dispatch('setSnackbar', {
          text: 'Trusted Contact Person detail needs to be updated',
          type: 'error',
          topic: '',
        });
        this.$store.dispatch('flipSnackbarSwitch');
        this.onboardingSetupLoading = false;
        return;
      }
      try {
        if (this.hasFeatureFlag('new_workflows')) {
          // get all client IDs, check the accounts for joint clients
          const jointClientIds = accounts.reduce(
            (acc, r) => ((r.joint_client_id || []).length
              ? [...acc, ...r.joint_client_id]
              : acc),
            [],
          );
          const clientIds = [this.client.client.id, ...jointClientIds];
          const program = this.client.client.program;
          const accountIds = accounts.map((x) => x.id);
          await createOnboardingWorkflow(
            clientIds,
            accountIds,
            !inPerson,
            program,
          );
          if (inPerson) {
            if (this.isCCTFeature) {
              setTimeout(async () => {
                try {
                  const res = await getSessions(this.client.client.id);
                  const isNoCall = res.program !== 3;
                  const sessionToken = res.sessions[res.sessions.length - 1]
                    .session_token;
                  const surveyLink = `${window.location.origin}/advisor_portal/client-confirmation?sessionToken=${sessionToken}&firm=${this.client.client.firm_id}&noCall=${isNoCall}`;
                  window.location.href = surveyLink;
                } catch (err) {
                  this.$store.dispatch('setSnackbar', {
                    text: 'Error opening client onboarding tool',
                    type: 'error',
                  });
                  this.$store.dispatch('flipSnackbarSwitch');
                }
              }, 7000);
            } else {
              window.location.href = getSurveyEngineURL();
            }
          }
        } else {
          // TODO: DELETE THIS CODE ONCE NEW_WORKFLOWS FEATURE IS RELEASED
          await launchOnboarding(
            accounts,
            this.client.client,
            WORKFLOW.TYPES.client_onboarding,
            inPerson ? 'inPerson' : 'email',
          );
        }
      } catch (error) {
        this.onboardingSetupLoading = false;
        console.log('error launching onboarding', error.message);
        return;
      }

      this.$store.dispatch('getWorkflows', this.client.client.id);
      this.$store.dispatch('getInvestmentAccounts', this.client.client.id);

      if (inPerson) {
        this.currentScreen = 'surveyEngineLaunched';
      } else {
        this.currentScreen = 'surveyEmailSent';
      }

      this.onboardingSetupLoading = false;
    },
    triggerInPerson() {
      this.onboardingSetupLoading = true;
      this.triggerOnboarding();
    },
    triggerByEmail() {
      this.onboardingSetupLoading = true;
      this.triggerOnboarding(false);
    },
    removeAccount(index) {
      this.tempAccountsEdit.splice(index, 1);
    },
    openModal(modalType, account) {
      this.modalAccount = account;
      this[modalType] = true;
    },
    openRifDetailsOverlay(account) {
      this.modalAccount = account;
      this.rifModalOpen = true;
    },
    addAccount(baseType) {
      const obj = {
        currency: 'CAD',
        margin_status: false,
        options_status: false,
        icp_indicator: false,
        base_type_string: baseType,
        nickname: this.getNicknameFromBaseType(baseType),
        disableUSD:
          this.custodian === 'fcc'
            ? !this.isUSDPermittedFCC(baseType)
            : this.currencyValidation(baseType),
        tempID: uuidv4(),
        generateEstate: false,
        mandate_name: '',
      };
      if (this.isRegisteredAccount(obj)) {
        obj.beneficiaries = [
          {
            tempID: uuidv4(),
            is_estate: true,
            client: this.client.client.id,
            priority: 'P',
            account: obj.tempID,
            percentage: '100',
            role: false,
          },
        ];
      }
      this.tempAccountsEdit.push(obj);
      this.toggleAddAccount();
      if (baseType === 'RESP') {
        this.setRespType(obj, 0);
        this.RESPDialog = obj;
      }
      if (baseType === 'RRSP' || baseType === 'SPOUSAL RRSP') {
        this.RSPDialog = {
          type: baseType === 'RRSP' ? 'I' : 'S',
          index: this.tempAccountsEdit.length - 1,
        };
      }
    },
    getNicknameFromBaseType(baseType) {
      const nicknamesMap = {
        'JOINT CASH': 'CASH-J',
        'SPOUSAL RRSP': 'RRSP-S',
        'SPOUSAL RRIF': 'RRIF-S',
        'JOINT CASH ITF': 'CASH-ITF-J',
      };
      return nicknamesMap[baseType] || baseType;
    },
    updateAccounts() {
      // Populate accounts with all necessary info for POSTing
      this.tempAccountsEdit.forEach((account) => {
        this.populateAccountInfo(account);
      });

      const accountsToAdd = this.tempAccountsEdit.filter((a) => !a.id);
      const unchangedAccounts = this.tempAccountsEdit.filter((a) => a.id);
      const accountsToDelete = this.tempAccounts.filter(
        (a) => !this.tempAccountsEdit.some((ea) => ea.id === a.id),
      );

      // Once added/deleted/edited accounts show up below
      const updatedAccounts = [];
      let updateFailed = false;

      const addPromises = accountsToAdd.map((a) => this.$store
        .dispatch('createAccount', {
          ...a,
          rif_details: undefined,
          beneficiaries_info: undefined,
        })
        .then((res) => {
          if (!res.id) throw Error;
          if (a.account_type_short >= 19 && a.account_type_short <= 23) {
            this.createRrifPayment(a, res.id);
          }
          if (a.beneficiaries.length) {
            this.createBeneficiaries(a, res.id);
          }
          if (a.trustee || a.trustee === 0) {
            this.createTrustee(a.trustee, res.id);
          }
          if (a.account_type_short === 17 || a.account_type_short === 18) {
            this.createRESPBeneficiaries(a, res.id);
          }
          if (this.clientStatus === 'Other Contact') {
            const data = { client_status: 'prospect' };
            this.$store.dispatch('updatePersonClient', {
              id: this.client.client.id,
              data,
            });
          }
          res.isNew = true;
          updatedAccounts.push(res);
        })
        .catch(() => { updateFailed = true; }));

      const deletePromises = accountsToDelete.map(async (a) => api
        .deleteCall(`/advisor_portal/workflow/delete_account/${a.id}/`)
        .then((res) => {
          if (!res) throw Error;
        })
        .catch(() => { updateFailed = true; }));

      return Promise.all(addPromises.concat(deletePromises))
        .then(() => {
          if (updateFailed) throw Error('update failed');
          // return all accounts to be added to the new workflow data
          return updatedAccounts.concat(unchangedAccounts);
        })
        .catch((err) => {
          console.log('err', err);
          throw Error('update failed');
        });
    },
    createRrifPayment(a, id) {
      const rrifPaymentInfo = {
        ...a.rif_details,
        withholding_tax_rate:
          a.rif_details.min_withholding_tax_rate_selection === 'custom'
            ? a.rif_details.custom_minimum
            : null,
        withholding_tax_rate_max:
          a.rif_details.max_withholding_tax_rate_selection === 'custom'
            ? a.rif_details.custom_maximum
            : null,
        withholding_tax_rate_excess:
          a.rif_details.excess_withholding_tax_rate_selection === 'custom'
            ? a.rif_details.custom_excess
            : null,
        excess_withholding_tax_rate_selection: undefined,
        minimum: a.rif_details.payment_type === 'min',
        lockedin_max_pay_selected: a.rif_details.payment_type === 'max',
        min_withholding_tax_rate_selection: undefined,
        payment_day:
          a.rif_details.first_payment_date.split('-')[2] === 15 ? 1 : 2,
        payment_year: a.rif_details.first_payment_date.split('-')[0],
        payment_month: a.rif_details.first_payment_date.split('-')[1],
        spousal_contribution:
          a.account_type_short === 20
            ? a.rif_details.spousal_contribution
            : undefined,
        payment_age_election:
          a.account_type_short === 20 || a.account_type_short === 19
            ? a.rif_details.payment_age_election
            : undefined,
        account: id,
        payto_bank_account:
          a.rif_details.payToType === PAY_TO_TYPE.BANK
            ? a.rif_details.account_id
            : null,
        payto_account_id:
          a.rif_details.payToType === PAY_TO_TYPE.INVESTMENT
            ? a.rif_details.account_id
            : null,
        payment_method: a.rif_details.payToType === PAY_TO_TYPE.BANK ? 1 : 4, // 2 and 3 are cheque and wire transfer, which we are not supporting
      };
      api
        .post('/datahub/rrif_pmt/', { ...rrifPaymentInfo })
        .catch((err) => console.log('rrif create error', err));
    },
    createRESPBeneficiaries(a, id) {
      const contributionsEndDate = moment()
        .year(moment().year() + 31)
        .format('YYYY-MM-DD');
      const planEndDate = moment()
        .year(moment().year() + 35)
        .format('YYYY-MM-DD');

      const beneficiaryRespPromises = [];
      a.beneficiaries_info.beneficiaries.forEach((beneficiary) => {
        const beneficiaryRespInfo = {
          subscriber_primary_caregiver: beneficiary.isPrimaryCaregiver,
          beneficiary_client: beneficiary.client_details[0].id,
          first_name: !beneficiary.isPrimaryCaregiver
            ? beneficiary.guardianFirstName
            : undefined,
          last_name: !beneficiary.isPrimaryCaregiver
            ? beneficiary.guardianLastName
            : undefined,
          contributions_end_date: contributionsEndDate,
          plan_end_date: planEndDate,
          primary_caregiver_client: !beneficiary.isPrimaryCaregiver && beneficiary.primaryCareGiver
            ? beneficiary.primaryCareGiver.value
            : undefined,
        };
        beneficiaryRespPromises.push(
          api
            .post(`/datahub/accounts/${id}/resp_beneficiaries/`, {
              ...beneficiaryRespInfo,
            })
            .catch((err) => console.log('resp beneficiary create error', err)),
        );
      });
      Promise.all(beneficiaryRespPromises);
    },
    createBeneficiaries(account, id) {
      if (
        account.beneficiaries.length === 1
        && account.beneficiaries[0].is_estate
        && !account.generateEstate
      ) {
        // do not create any beneficiaries
        return;
      }
      Promise.all(
        account.beneficiaries
          .filter((b) => Number(b.percentage) !== 0)
          .map((b) => {
            b.percentage = Number(b.percentage) / 100;
            b.account = id;
            b.role = b.role || 'BN';
            if (b.is_estate) delete b.client;
            return this.$store.dispatch('addBeneficiary', b);
          }),
      ).catch((err) => console.log('adding beneficiaries err', err));
    },
    createTrustee(trustee, id) {
      this.$store
        .dispatch('addRESPgrants', {
          trustee_to_apply: trustee,
          cesg_grant_flag: trustee,
          additional_cesg_grant_flag: trustee,
          clb_grant_flag: trustee,
          provincial_grant_flag: trustee,
          account: id,
        })
        .catch((err) => console.log('adding trustee err', err));
    },
    setJointType(account) {
      if (account.joint_indicator) {
        if (!account.joint_type) {
          return 2;
        }
        return account.joint_type;
      }
      return 0;
    },
    populateAccountInfo(account) {
      // assume base type, margins, options, currency, joint type, joint relationship, jurisdiction, are all accurate
      // TODO switch to using string rather than index as identifier, so list can be modified in terms of positions easily
      const baseType = this.baseAccType(account);
      // set defaults to CASH account
      const obj = {
        rep_code: this.client.client.rep_code,
        primary_client_id: this.client.client.id,
        currency: account.currency,
        spousal_indicator: !!(
          baseType === 'SPOUSAL RRSP' || baseType === 'SPOUSAL RRIF'
        ),
        locked_in_indicator: false,
        discretionary_status: false,
        registration_status: false,
        trading_status: false,
        margin_status: account.margin_status,
        options_status: account.options_status,
        icp_indicator: account.icp_indicator,
        mandate_name: account.icp_indicator ? account.mandate_name || '' : '',
        employee_account: '0',
        joint_indicator: account.joint_indicator,
        joint_client_id: account.joint_client_id ? account.joint_client_id : [],
        jurisdiction:
          account.jurisdiction === 'NA' ? '' : account.jurisdiction || '',
        account_status: account.account_status ? account.account_status : 'T',
        non_resident_code: false,
        is_deleted: false,
        resp_type:
          account.resp_type
          || (ACCOUNTTYPE[account.account_type_short] === 'RESP-F' ? 1 : 0),
        account_type_short: 1,
        account_type_long: 1,
        rap_code: 'A',
        joint_type: this.setJointType(account), // 1 for tenants in common, 2 for right of survivorship
        account_group: '1',
        subtype: '',
        nickname: account.nickname,
        beneficiaries: account.beneficiaries ? account.beneficiaries : [],
        trust_indicator: 0,
        nominee: null,
        use_of_account: account.use_of_account,
      };

      switch (baseType) {
        case 'CASH':
          obj.account_type_short = account.margin_status ? 2 : 1;
          obj.subtype = '';
          if (account.currency === 'CAD') {
            obj.rap_code = account.margin_status ? 'E' : 'A';
          }
          if (account.currency === 'USD') {
            obj.rap_code = account.margin_status ? 'F' : 'B';
          }
          obj.account_group = account.margin_status ? '6' : '1';
          break;
        case 'CASH ITF':
          obj.account_type_short = 25;
          obj.subtype = '';
          if (account.currency === 'CAD') {
            obj.rap_code = account.margin_status ? 'E' : 'A';
          }
          if (account.currency === 'USD') {
            obj.rap_code = account.margin_status ? 'F' : 'B';
          }
          obj.account_group = '1';
          obj.trust_indicator = 1;
          obj.nominee = account.nominee;
          break;
        case 'RRSP':
          obj.account_type_short = 13;
          obj.subtype = 'R';
          obj.rap_code = 'S';
          obj.account_group = '2';
          break;
        case 'TFSA':
          obj.account_type_short = 24;
          obj.subtype = 'W';
          obj.rap_code = 'V';
          obj.account_group = '3';
          break;
        case 'RESP':
          obj.account_type_short = obj.resp_type === 1 ? 17 : 18;
          obj.subtype = obj.resp_type === 1 ? 'E' : 'V'; // individual or family
          obj.rap_code = 'Q';
          obj.account_group = '4';
          obj.trastee = account.trustee;
          break;
        case 'RRIF':
          obj.account_type_short = 19;
          obj.subtype = 'N';
          obj.rap_code = 'T';
          obj.account_group = '5';
          break;
        case 'LIF':
          obj.account_type_short = 23;
          obj.subtype = 'X';
          obj.rap_code = 'T';
          obj.account_group = '5';
          obj.locked_in_indicator = true;
          break;
        case 'LRIF':
          obj.account_type_short = 22;
          obj.subtype = 'Z';
          obj.rap_code = 'T';
          obj.account_group = '5';
          obj.locked_in_indicator = true;
          break;
        case 'PRIF':
          obj.account_type_short = 21;
          obj.subtype = 'P';
          obj.rap_code = 'T';
          obj.account_group = '5';
          obj.locked_in_indicator = true;
          break;
        case 'LRSP':
          obj.account_type_short = 15;
          obj.subtype = 'L';
          obj.rap_code = 'S';
          obj.account_group = '2';
          obj.locked_in_indicator = true;
          break;
        case 'LIRA':
          obj.account_type_short = 16;
          obj.subtype = '0';
          obj.rap_code = 'S';
          obj.account_group = '2';
          obj.locked_in_indicator = true;
          break;
        case 'JOINT CASH':
          obj.account_type_short = account.margin_status ? 6 : 5;
          obj.subtype = '';
          if (account.currency === 'CAD') {
            obj.rap_code = account.margin_status ? 'E' : 'A';
          }
          if (account.currency === 'USD') {
            obj.rap_code = account.margin_status ? 'F' : 'B';
          }
          obj.account_group = account.margin_status ? '6' : '1';
          break;
        case 'JOINT CASH ITF':
          obj.account_type_short = 26;
          obj.subtype = '';
          if (account.currency === 'CAD') {
            obj.rap_code = account.margin_status ? 'E' : 'A';
          }
          if (account.currency === 'USD') {
            obj.rap_code = account.margin_status ? 'F' : 'B';
          }
          obj.account_group = '1';
          obj.trust_indicator = 1;
          obj.nominee = account.nominee;
          break;
        case 'SPOUSAL RRSP':
          obj.account_type_short = 14;
          obj.subtype = 'S';
          obj.rap_code = 'S';
          obj.account_group = '2';
          break;
        case 'SPOUSAL RRIF':
          obj.account_type_short = 20;
          obj.subtype = 'T';
          obj.rap_code = 'T';
          obj.account_group = '5';
          break;
        default:
          break;
      }

      obj.account_type_long = obj.account_type_short;
      Object.assign(account, obj);
    },
    toggleAddAccount() {
      this.addingAccount = !this.addingAccount;
      if (this.addingAccount) {
        this.resetIdleTimer();
        this.setVisibleAccountTypes();
      }
    },
    editRESPSubscriber(subscriber) {
      this.subscriber = subscriber;
      this.isEditingSubscriber = true;
    },
    setVisibleAccountTypes() {
      const availableWidth = this.$refs.accountLayout.offsetWidth - 120;
      const numberOfItems = Math.floor(availableWidth / 110);
      this.paginationLength = this.baseAccountTypes.length + 1 - numberOfItems;
      this.visibleAccountTypes = this.baseAccountTypes
        .concat(['JOINT'])
        .slice(this.page - 1, numberOfItems + this.page - 1);
    },
    setBaseType(account, $event) {
      this.$set(account, 'currency', 'CAD');
      this.$set(account, 'margin_status', false);
      this.$set(account, 'options_status', false);
      this.$set(account, 'icp_indicator', false);
      this.$set(account, 'mandate_name', '');
      this.$set(account, 'base_type_string', $event);
      this.$set(account, 'nickname', this.getNicknameFromBaseType($event));
      if (!this.isRegisteredAccount(account)) {
        this.$set(account, 'beneficiaries', []);
        this.$set(account, 'generateEstate', false);
      }
      if ($event === 'RESP') this.RESPDialog = account;
      if ($event === 'RRSP' || $event === 'SPOUSAL RRSP') {
        this.RSPDialog = {
          type: $event === 'RRSP' ? 'I' : 'S',
          index: this.tempAccountsEdit.indexOf(account),
        };
      }
    },
    setAccountOptions(optionsArray, account) {
      account.margin_status = optionsArray.includes(0);
      account.options_status = optionsArray.includes(1);
      account.icp_indicator = optionsArray.includes(2);
    },
    validateBeneficiaries() {
      this.beneficiaryError = false;
      this.beneficiaryErrorText = [];
      this.tempAccountsEdit.forEach((account) => {
        if (account.beneficiaries && account.beneficiaries.length) {
          const clients = account.beneficiaries
            .map((b) => b.client)
            .filter((c) => c);
          // check if there are beneficiaries without a client
          const hasNoClient = account.beneficiaries.some((b) => !b.client);
          if (hasNoClient) {
            this.beneficiaryError = true;
            this.beneficiaryErrorText.push(`Please select the beneficiary from the list of relationships for ${account.nickname}.\n`);
          }
          // check if there are duplicate beneficiaries
          const hasDuplicates = clients.some(
            (c, i) => clients.indexOf(c) !== i,
          );
          if (hasDuplicates) {
            this.beneficiaryError = true;
            this.beneficiaryErrorText.push(`Duplicated beneficiary for ${account.nickname}.\n`);
          }
          // check if percentages of contingent beneficiaries add up to 100
          const beneficiariesByType = account.beneficiaries.filter(
            (b) => b.priority === 'C',
          );
          if (beneficiariesByType.length) {
            const percentage = beneficiariesByType
              .map((b) => Number(b.percentage))
              .reduce((a, b) => a + b, 0);
            if (percentage > 100) {
              this.beneficiaryError = true;
              this.beneficiaryErrorText.push(`Percentages cannot exceed 100 for ${account.nickname}.\n`);
            }
          }
          // check if there are beneficiaries with zero or negative percentages
          const lessThanZero = account.beneficiaries.filter(
            (b) => Number(b.percentage) <= 0,
          );
          if (lessThanZero.length !== 0) {
            // check if is_estate is false for any beneficiaries with negative percentages
            const hasNonEstate = lessThanZero.some((b) => !b.is_estate);
            if (hasNonEstate) {
              this.beneficiaryError = true;
              this.beneficiaryErrorText.push(`Percentage must be greater than 0 for ${account.nickname}.\n`);
            }
          }
        }
      });
      if (this.beneficiaryError) return;
      if (this.clientIsNotActive) {
        this.continueClickNext();
      } else {
        this.loadSaveOptionsScreen();
      }
    },
  },
};
</script>

<style lang="scss">
@import "~Styles/variables";
.disabled {
  opacity: 0.4 !important;
  pointer-events: none !important;
}
.no-pointer {
  &.v-item-group button {
    background-color: #ddd !important;
  }
}
.btnBottomMargin {
  .v-btn {
    margin-bottom: 5px !important;
  }
}
.btnSmall {
  .v-btn {
    margin-bottom: -5px !important;
  }
}
.flexColumn {
  flex-direction: column;
}
.accounts-table-header-layout {
  .flex {
    margin-right: 10px !important;
  }
}
.accounts-row-layout {
  .flex {
    margin-right: 10px !important;
  }
  .drop-down .v-input__slot {
    margin-bottom: 0px !important;
  }
}
.accounts-container {
  padding-top: 18vh !important;
  max-width: 1200px !important;
  width: 80vw !important;
}
@media screen and (max-width: 992px) {
  .accounts-container {
    padding-top: 18vh !important;
    max-width: 1200px !important;
    width: 95vw !important;
  }
}
.pas-list-wrapper {
  box-shadow: none !important;
}
.cover-hr {
  position: relative;
  bottom: -10px;
  z-index: 99 !important;
  overflow: hidden !important;
  background: white !important;
  padding-right: 20px;
}
.choose-from-div {
  border: 0.5px solid rgba(0, 0, 0, 0.4);
  color: $regular-text !important;
  border-radius: 10px !important;
  height: 3.5em !important;
  width: 100px !important;
  min-width: 100px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  text-align: center;
  cursor: pointer;
  &:hover {
    color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
  }
}
.hide-pagination-items {
  .v-pagination__item,
  .v-pagination__more {
    display: none !important;
  }
}
.slide-fade-enter-active {
  transition: all 2s ease;
}
// .slide-fade-leave-active {
//   transition: all 3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
// }
.slide-fade-enter
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(80px);
  opacity: 0;
}

.combo-menu {
  position: absolute;
  top: 53px;
  background-color: white !important;
  width: 250px;
  z-index: 999;
  height: 77px;
  padding: 15px;
  box-shadow: $soft-box-shadow !important;
}

.investment-accounts-loader {
  height: 100vh !important;
  .loader-container {
    display: flex;
    flex-direction: column;
    align-content: center !important;
    justify-content: center !important;
  }
}

.dropdown-label {
  .v-input__control {
    max-height: 35px;
    .v-input__slot .v-select__slot label {
      color: var(--primary-color);
    }
    .v-input__slot .v-select__slot .v-input__append-inner {
      display: none;
    }
    .v-input__slot:before {
      border-style: none;
    }
    .v-input__slot:after {
      border-style: none;
    }
  }
}

.dropdown-label.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
> .v-input__control
> .v-input__slot {
  box-shadow: none;
  background: none;
}

.dropdown-label.v-text-field.v-text-field--solo .v-input__control {
  min-height: 0px;
}

.dropdown-label.v-text-field.v-text-field--enclosed
> .v-input__control
> .v-input__slot {
  padding: 0 0 0 0;
  margin-bottom: 0px;
}

.icp-mandate .v-chip .v-chip__content {
  font-size: 12px;
  height: 24px;
  color: var(--primary-color);
  padding: 0px 8px;
}

.icp-mandate .theme--light.v-chip {
  background: var(--primary-translucent-2);
}

.icp-mandate .v-chip--selected:after {
  background: none;
}

.inv-account-dropdown.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.successor-checkbox.v-input.v-input--selection-controls.v-input--checkbox {
  padding-top: 0px;
}
.successor-checkbox {
  .v-messages.theme--light {
    display: none !important;
  }
}
.delete-beneficiary {
  position: absolute;
  top: 12px;
  right: 130px;
}
.account-flex {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
h4.line-div {
  position: relative;
}

h4.line-div span {
  background-color: white;
  padding-right: 10px;
  z-index: 2 !important;
}

.fcc-gender-radios.v-input--selection-controls .v-input__control {
  width: 100%;
}

h4.line-div:after {
  content: "";
  position: absolute;
  bottom: 2px;
  left: 0;
  right: 0;
  height: 0.5em;
  border-top: 0.5px solid rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.joint-subscriber {
  height: 21px;
}

#resp-joint-subscriber .v-select__selections {
  width: 122px;
}

.error-list {
  list-style-type: none;
  padding: 0;
}

.error-item {
  margin-bottom: 10px;
}
</style>
